import unique from "./unique";

const imageTexts = (content,language) => {
    const items = [];

    const keys = Object.keys(content);

    keys
      .filter(key => key.includes("Image_"))
      .map(key => {
        let array = key.split("_");
  
        array.pop();
  
        return array.join("_");
      })
      .filter(unique)
      .forEach(key => {
        items.push({
          title: content[`${key}_title`][language],
          subtitle: content[`${key}_subtitle`][language]
        });
      });
    return items;
};

export default imageTexts;