import React from "react";

import icons_sprite from "../../images/dist/svg/sprites/icons_sprite.svg";
import "./SVGSpriteImg.scss";

function SVGSpriteImg({ name, color, size }) {
  let viewBox = `0 0 ${size} ${size}`;
  return (
    <svg width={size}
         fill={color}
         viewBox={viewBox}>
      <use href={icons_sprite + `#${name}`} />
    </svg>
  );
}

export default SVGSpriteImg;