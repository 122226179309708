function addExternalGLobeScript(path) {
    return new Promise(resolve => {
      const script = document.createElement("script");
      script.src = path;
      script.async = true;
      document.body.appendChild(script);
  
      setTimeout(resolve,2000);
    }); 
}

export default addExternalGLobeScript;