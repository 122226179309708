const supportedLanguages = [
    "English",
    "Italian",
    "Spanish",
    "Portuguese",
    "Thai",
    "Malaysian",
    "Indonesian"
];
const defaultLanguage = "English";

function getLanguage() {
    const params = new URLSearchParams(window.location.search);

    let language = defaultLanguage;
    let providedLanguage = undefined;

    if(params.has("language")) {
        providedLanguage = params.get("language");
    } else if (Object.prototype.hasOwnProperty.call(window.localStorage, "language")) {
        providedLanguage = window.localStorage.getItem("language");
    }
    
    if(providedLanguage && supportedLanguages.includes(providedLanguage)) {
        language = providedLanguage;
        window.localStorage.setItem("language",providedLanguage);
    }

    return language;
}

export default getLanguage;