import React from "react";

const itemsToHide = ["Launch Link","Available Product"];

const formats = {
  "Anonymized Mobility GPS Data": {
    "Data Volume": (originalValue) => {
      let formattedValue = `${parseInt(originalValue)}`;

      return formattedValue;
    }
  }
};

function formatValue(section,item,value) {
  let toReturn = value;

  if(formats[section] && formats[section][item]) {
    toReturn = formats[section][item](value);
  }

  return toReturn;
}

function targetCityDetails(targetCity,units,content) {
    if(!content) 
      return <></>;

    const sections = {};
    
    Object.keys(targetCity).forEach(key => {
      const keyPair = key.split(" - ");

      const sectionKey = keyPair[0];
      const sectionItem = keyPair[1];

      if(sectionItem) {
        if(!sections[sectionKey]) {
          sections[sectionKey] = {};
        }

        sections[sectionKey][sectionItem] = targetCity[key];
      }
    });

    const getRow = (sections,section,idx,item,url) => {
      let row = <></>;

      if(sections["Population Density Patterns"]["Available Product"] === "true") {
        // document.querySelector(".buttonAboveInput").style.display = "block";
      } else {
        // document.querySelector(".buttonAboveInput").style.display = "none";
      }

      if(section === "Population Density Patterns" && item === "Matching Product") {
        if(sections[section]["Available Product"] === "true") {
          row = <tr key={idx}></tr>;
        }
      } else {
        row = <tr key={idx}>
          <td>{item.split("-")[0]}</td>
          <td>
            {item === "Matching Product" ? <a href={url} target="_blank" rel="noreferrer">
              <span>
                {sections[section]["Matching Product"]}
              </span>
            </a> : formatValue(section,item,sections[section][item]) } {/* value */}
            {units[`${section} - ${item}`] !== "null" ? units[`${section} - ${item}`] : ""} {/* description */}
          </td>
        </tr>;        
      }
      
      return row;
    };

    return (
      <div id="citiesDetails">
        <div className="topic">
          <table>
            <tbody>
              {
                Object.keys(sections).map(section => {
                  return (<>
                    <tr>
                      <td colSpan={2}>
                        <h2>
                          {section}
                        </h2>
                      </td>
                    </tr>
                    {
                      Object.keys(sections[section]).sort((a,b) => a.split("-")[1] - b.split("-")[1]).map((item,idx) => {
                        let url = sections[section]["Launch Link"]; 

                        if(sections && section && sections[section] && sections[section]["Launch Link"] && sections[section]["Launch Link"].includes("citydash")) {
                          if(window.location.search){
                            url = `${sections[section]["Launch Link"]}${window.location.search}`;
                          } else {
                            url = `https://auth.citydata.ai/?redirectTo=${sections[section]["Launch Link"]}`;
                          }
                        }

                        return itemsToHide.includes(item) || !sections[section][item] ? 
                          <></> : getRow(sections,section,idx,item,url);
                      })
                    }                    
                  </>);
                })
              }
            </tbody>
          </table>
        </div>
      </div>    
    );
}

export default targetCityDetails;