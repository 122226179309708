import React from "react";
import IntroContent from "../IntroContent/IntroContent";

import DescriptionContent from "../../components/DescriptionContent/DescriptionContent";
import "./SummaryContent.scss";

function summaryContent(contentData,language,section) {
    if(contentData == undefined) 
      return <></>;
  
    const content = contentData[`${section} `][" home"];
    const menuGroup_key = Object.keys(contentData["Navigation"])
      .filter(k => contentData["Navigation"][k].English)
      .find(k => contentData["Navigation"][k].English.toLowerCase() == section);

    const menuGroup_items = Object.keys(contentData["Navigation"])
      .filter(k => k.includes(`${menuGroup_key}_`))
      .filter(submenu_key => contentData["Navigation"][submenu_key]["Has_content"])
      .map(submenu_key => contentData["Navigation"][submenu_key]);

    return <section id="SummaryContent">
        {IntroContent(
          contentData[`${section} `][" home"]["Blob_image"]["Relevent_Link"],
          content["Headline_1"][language],
          "",
          content["Headline_2"][language]
      )}

      <div className="container">
          <div className="main">
              <article id="description">
                {DescriptionContent(content["Description_text_1"],language)}
                {DescriptionContent(content["Description_text_2"],language)}
              </article>
              <ol id="sections">
                  {menuGroup_items.map(item => 
                    <li key={item["Parameter_Label"]}
                        id={item["Parameter_Label"]}
                        className="section">
                      <a href={item["Relevent_Link"]}
                         target={item["Target"]}>
                        {item[language]}
                      </a>
                    </li>
                  )}
              </ol>
          </div>
      </div>
    </section>;
}

export default summaryContent;