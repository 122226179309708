import React, { useState } from "react";

// import SliderControls from "../SliderControls/SliderControls";

import "./Citydash.scss";
import ProductDescription from "../ProductDescription/ProductDescription";
import tableTexts from "../../helpers/tableTexts";
import descriptionTexts from "../../helpers/descriptionTexts";
import S3Image from "../../helpers/S3Image";
import playSlider from "../../helpers/playSlider";

function customImagery() {
  const images = [
    S3Image("CITYDASH/scaled/dist/CITYDATA_CITYDASH_On_Demand_Surveys_Pay_As_You_Go_Machine_Learning_Digital_Replicas.png?cache=12"),
    S3Image("CITYDASH/scaled/dist/CITYDATA_CITYDASH_Florida_Hurricanes_Disaster_Impact_Migration_Patterns.png?cache=12"),
    S3Image("CITYDASH/scaled/dist/CITYDATA_CITYDASH_Colombia_Venezuela_Conflict_Zone_Migration_Patterns.png?cache=12"),
    S3Image("CITYDASH/scaled/dist/CITYDATA_CITYDASH_San_Francisco_Silicon_Valley_Bay_Area_California_Crime_Patterns.png?cache=12"),
    S3Image("CITYDASH/scaled/dist/CITYDATA_CITYDASH_Jakarta_Indonesia_Economic_Development_Place_Visits_Footfalls.png?cache=12"),
    S3Image("CITYDASH/scaled/dist/CITYDATA_CITYDASH_Rio_de_Janeiro_Urban_Air_Mobility_Routes_Vertiports.png?cache=12"),
    S3Image("CITYDASH/scaled/dist/CITYDATA_CITYDASH_Bologna_IoT_Site_Selection_People_Density.png?cache=12"),
    S3Image("CITYDASH/scaled/dist/CITYDATA_CITYDASH_San_Francisco_Golden_Gate_Park_Visits_Footfalls_Slow_Streets.png?cache=12")
  ];

  playSlider("#citydash",images.length);
  return (
    <div className="laptopMockup devices">
      <div className="frame"></div>

      <div className="collectionHolder">
          <div className="collection">
            <div className="images">
              <div className="scroller">
                {images.map((imgSrc,idx) => 
                  <figure className="hasImage" key={idx}>
                    <img className="citydashImage" data-src={imgSrc} alt="" />
                  </figure>
                )}
              </div>
            </div> {/* .collection */}            
          </div>
      </div>
    </div>    
  );
}
function getContent(language,content, slidePosition) {
  if(!content) 
    return <></>;

  return (
    <div id="citydash" className="hasSectionContent">
      <ProductDescription 
          Headline_1={content.CityDash.Headline_1[language]}
          Headline_2={content.CityDash.Headline_2[language]}
          Button={content.CityDash.Button[language]}
          ButtonHref={content.CityDash.Button["Relevent_Link"]}
          descriptionTexts={descriptionTexts(content.CityDash,language)}
          tableTexts={tableTexts(content.CityDash,language)}
          imagery={customImagery(slidePosition)}
          SectionHeaderLogoImagePath="https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/citydash_cd_blob_2letter.png"
          SectionHeaderLogoImageDescription="CityDash badge logo"
      />
    </div>
  );
}

function Citydash({language,content}) {
  const [slidePosition] = useState(0);

  return getContent(language,content, slidePosition);
}

export default Citydash;