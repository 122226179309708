import React from "react";

import DOMPurify from "dompurify";
import "./InternalContent.scss";

import SectionHeader from "../SectionHeader/SectionHeader";

import HighlightedCustomer from "../HighlightedCustomer/HighlightedCustomer";
import DescriptionContent from "../../components/DescriptionContent/DescriptionContent";
import internalSectionsContent from "../../helpers/internalSectionsContent";

function sectionContent(key,title,description) {
    return (<li className="section" key={key}>
        <h3 className="title">{title}</h3>
        <p className="description"
           dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(description)}}
        ></p>
    </li>);
}
function getSections(content,language) {
    return internalSectionsContent(content,language).map((section,key) => 
        sectionContent(
            key,
            section.title,
            section.description,
            section.image,
            content,
            language,
            section.table
        )
    );
}
function getInternalContent(contentData,language,section,params,HighlightedCustomerData = null) {
    if(!contentData) 
      return <></>;
    
    const content = contentData[`${section}`][`${params.topic}`];

    return (<section id="InternalContent" className={params.topic}>
        <SectionHeader
            Headline_1={content["Headline_1"][language]}
            Headline_2={content["Headline_2"][language]}
        />

        <div className="container main">
            <div>
                <article id="description">
                    <div id="DescriptionContent">
                        {DescriptionContent(content["Description_text_1"],language)}
                        {DescriptionContent(content["Description_text_2"],language)}
                    </div>
                    {HighlightedCustomerData ? <div id="hasHighlightedCustomer">
                        {HighlightedCustomer(HighlightedCustomerData)}</div>
                        : <></>
                    }
                </article>

                <ol id="sections">
                    {getSections(content,language)}
                </ol>
            </div>
        </div>
    </section>);
}

export default getInternalContent;