const contentSteps = (content,language) => {
    let steps = null;

    [...new Set(Object.keys(content).filter(key => key.includes("Section") && key.includes("_Step_")).map(i => `Step_${i.split("_Step_")[1].split("_")[0]}_`))].forEach(step => {
        if(!steps) {
            steps = {};
        }

        steps[step] = {};
    
        Object.keys(content)
            .filter(key => key.includes(step))
            .forEach(stepField => {
                let cleanField = stepField
                    .split("_Step_")[1]
                    .split("")
                    .filter((letter,idx) => idx > 1)
                    .join("");

                steps[step][cleanField] = content[stepField][language];
                
                if(content[stepField][language].length == 0) {
                    steps[step][cleanField] = content[stepField]["Relevent_Link"];
                }
            });
    });

    return steps;
};

export default contentSteps;