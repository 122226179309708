let data = undefined;
const Locale = {
    build:(setContentData) => {
        return new Promise((resolve,reject) => {
            try {
                if(Locale.data) {
                    throw new Error("Locale.build() can only be called once");
                }

                fetch(`https://citydata.ai/static/content.json?cache=${Math.floor(Math.random() * 200000)}`)
                    .then(res => res.json())
                    .then(contentData => {
                        if(!Locale.data) {
                            Locale.data = contentData;

                            setContentData(Locale.data);
                            resolve(Locale.data);
                        }
                    });
            } catch(error) {
                if(error.message === "Locale.build() can only be called once") {
                    resolve(Locale.data);
                } else {
                    console.log(error);
                    reject(error);
                }
            }
        });
    },
    init: (setContentData) => {
        return new Promise((resolve,reject) => {
            try {
                Locale.build(setContentData).then((data) => resolve(data));
            } catch(error) {
                if(error.message === "Locale.init() can only be called once") {
                    resolve(Locale.data);
                } else {
                    console.log(error);
                    reject(error);
                }
            }
        });
    },
    data:data
};
export default Locale;