import React, { useState } from "react";
import SectionHeader from "../SectionHeader/SectionHeader";

import S3Image from "../../helpers/S3Image";
import unique from "../../helpers/unique";

import "./Customers.scss";
import HighlightedCustomer from "../HighlightedCustomer/HighlightedCustomer";

const list = [];
const customersList = (content,language) => {
  if(list.length) return list;

  Object.keys(content)
    .filter(key => key.includes("Logo"))
    .map(key => {
      let array = key.split("_");

      let g = [array[0],array[1]];

      return g.join("_");
    })
    .filter(unique)
    .map(key => {
      let o = {};

      if(content[`${key}_image`]){
        o.company = content[`${key}_image`][language];
      }
      if(content[`${key}_endorser_name`]){
        o.endorser_name = content[`${key}_endorser_name`][language];
        o.endorser_photo = content[`${key}_endorser_name`]["Relevent_Link"];
      }
      if(content[`${key}_endorser_title`]){
        o.endorser_title = content[`${key}_endorser_title`][language];
      }
      if(content[`${key}_endorser_text`]){
        o.endorser_text = content[`${key}_endorser_text`][language];
      }

      // if(content[`${key}_endorser_image`]){
        o.alt = content[`${key}_image`][language];
        let content_key_image = content[`${key}_image`]["Relevent_Link"];
        o.image = S3Image(`customers logos/dist/${content_key_image}`);
      // }

      list.push(o);
    });

  return list;
};

function getContent(language,content,HighlightedCustomerData,updateCustomer) {
  if(!content) 
    return <></>;

  const customersListContainer = customersList(content.Customers,language); 
  const customersListContainer_endorsed = customersListContainer.filter(item => 
    item.endorser_name && item.endorser_title && item.endorser_text
  );
  const customersListContainer_nonEndorsed = customersListContainer.filter(item => 
    !item.endorser_name || !item.endorser_title || !item.endorser_text
  );
  const allCustomersCount = customersListContainer_endorsed.length + customersListContainer_nonEndorsed.length;
  const classNameWithCount = `allCustomersCount-${allCustomersCount}`;
  return (
      <section id="Customers" className="hasSectionContent">
          <SectionHeader
            Headline_1={content.Customers.Headline_1[language]}
            Headline_2={content.Customers.Headline_2[language]}/>

        <div className="container">
          <div id="hasCustomersContent">
            <article id="hasCustomersList" className="container">
              <ul id="customersList" className={classNameWithCount}>
              {customersListContainer_endorsed.map((customer,index) => 
                  <li className="customer" key={index}>
                    <button type="button"
                            className="item"
                            onClick={function() {
                              updateCustomer(customer);
                            }}>
                      <figure>
                        <img
                          data-src={customer.image}
                          alt={customer.alt} />
                      </figure>
                    </button>
                  </li>)}
              {customersListContainer_nonEndorsed.map((customer,index) => 
                  <li className="customer" key={index}>
                    <div className="item">
                      <figure>
                        <img
                          data-src={customer.image}
                          alt={customer.alt} />
                      </figure>
                    </div>
                  </li>)}
              </ul>
            </article>

            <article id="hasHighlightedCustomer">
              {HighlightedCustomer(HighlightedCustomerData)}
            </article>
          </div>
        </div>
      </section>
  );
}

let autoDefineHighlightedCustomerData = false;
function Customers({language,content}) {
  const [HighlightedCustomerData, setHighlightedCustomerData] = useState();
  const updateCustomer = (customer) => {
    setHighlightedCustomerData(customer);
  };

  const c = getContent(language,content,HighlightedCustomerData,updateCustomer);

  if(!autoDefineHighlightedCustomerData) {
    let waitForContent = setInterval(() => {
      if(list.length) {
        clearInterval(waitForContent);
        document.querySelector("#customersList > li:nth-child(1) > button").click();
      }
    },1);

    autoDefineHighlightedCustomerData = true;
  }

  return c;
}

export default Customers;