import React from "react";
import SectionHeader from "../SectionHeader/SectionHeader";

import getRSS from "../../helpers/getRSS";
import "./News.scss";

function getContent(language,content,topic,posts,setPosts) {
  if(!content) 
    return <></>;

  if(!posts.length) {
    getRSS(topic).then(rssPosts => {
      setPosts(rssPosts);
    });
  }

  return (posts && Array.isArray(posts) && posts.length && <section id="News">
      <SectionHeader
        Headline_1={content.News.Headline_1[language]}
        Headline_2={content.News.Headline_2[language]}
        Button={content.News.Button[language]}
        ButtonHref={content.News.Button["Relevent_Link"]}/>

      {posts ? <div className="container">
        <div className="blogContent">
          <ul className="hasPosts" style={{width: `${posts.length * 30}vw`}}>
            {posts.map((item,idx) => <li key={idx} className="blogPost">
                <article>
                  <figure>
                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                      <img data-src={item.enclosure.link} alt={item.title} />
                    </a>
                  </figure>

                  <h4 className="title">
                    <a className="link" href={item.link} target="_blank" rel="noopener noreferrer">
                      {item.title}
                    </a>
                  </h4>
                </article>
              </li>
            )}
          </ul>
        </div>
      </div> : <></>}
    </section>
  );
}

function News({language,content,topic}) {
  const [posts,setPosts] = React.useState([]);
  return getContent(language,content,topic,posts,setPosts);
}

export default News;