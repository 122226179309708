import React from "react";

import "./FreeConsultation.scss";

function FreeConsultation() {
  return (
    <iframe id="freeconsultation" src="https://hub.citydata.ai/free-consultation"></iframe>
  );
}

export default FreeConsultation;