/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import DOMPurify from "dompurify";
import "./Aboutus.scss";

import {
  setupGlobe,  initGlobe, setStickyGlobe
} from "../../helpers/Globe";

import Header from "../Header/Header";
import content from "../../helpers/Content";
import Footer from "../Footer/Footer";

import TagManager from "react-gtm-module";
import getLanguage from "../../helpers/getLanguage";

import SectionHeader from "../SectionHeader/SectionHeader";
import DescriptionContent from "../DescriptionContent/DescriptionContent";
import internalSectionsContent from "../../helpers/internalSectionsContent";
import LoaderAnimation from "../../components/LoaderAnimation/LoaderAnimation";
import SignIn from "../SignIn/SignIn";

TagManager.initialize({
  gtmId: "GTM-TWZTBF5"
});


function radFromLat(lat) {
  return lat * (Math.PI / 180);
}
function radFromLng(lng) {
  return -lng * (Math.PI / 180);
}

function rotateGlobeToTargetCity(memberPosition) {
  return new Promise((resolve) => {

    const roundsToComplete = 1;
  
    const currentPositionY = Number(setupGlobe.GLB.rotation.y);
    const currentPositionX = Number(setupGlobe.GLB.rotation.x);
  
    const nextPositionY = radFromLng(memberPosition.lng);
    const nextPositionX = radFromLat(memberPosition.lat);
  
    const diffY = nextPositionY - currentPositionY;
    const diffX = nextPositionX - currentPositionX;
  
    setupGlobe.partialY = diffY / roundsToComplete;
    setupGlobe.partialX = diffX / roundsToComplete;
  
    (function checkTravelingStatus(){
      if(setupGlobe.round == roundsToComplete) {
        setupGlobe.partialY = 0;
        setupGlobe.partialX = 0;
  
        setupGlobe.round = 0;

        resolve();
      } else {
        setupGlobe.round = setupGlobe.round + 1;
        requestAnimationFrame(checkTravelingStatus);
      }
    })();
  });
}

function highilightMemberOnGlobe (member){
  if(setupGlobe.globeAnimationStarted) {
    setupGlobe.GLB
      .ringsData([member.position])
      .ringColor(() => "rgba(255,255,255,1)")
      .ringMaxRadius(6)
      .ringPropagationSpeed(-1)
      .ringRepeatPeriod(1000);
  
    rotateGlobeToTargetCity(member.position);
  }
}

function teamMembers(contentData,language) {
  const content = contentData["company"]["about"];

  const members = [...new Set(Object.keys(content).filter(
    key => key.includes("person")
  ).map(
    k => k.split("_").filter((i,idx) => idx <= 1).join("_")
  ))];

  const membersContent = members.sort((a,b) => parseInt(a.split("_")[1]) - parseInt(b.split("_")[1])).map(member => {
    let memberData = {};
    
    try {
      const personFields =  Object.keys(content).filter(item => item.includes(`${member}_`));
  
      personFields.forEach(field => {
        let a = field.split("_");
            a.pop();
        let person = a.join("_");
  
        memberData[field.split("_").pop()] = content[field][language];
  
        memberData["link"] = content[`${person}_link`]["Relevent_Link"];
        memberData["photo"] = content[`${person}_name`]["Relevent_Link"];
  
        memberData["position"] = content[`${person}_location`]["English"]
          .split(",")
          .map(position => Number(position));
        memberData["position"] = {lat:memberData["position"][0],lng:memberData["position"][1]};
      });
    } catch(e) {
      memberData = null;
    }
    
    return memberData;
  }).filter(memberData => memberData);

  return membersContent;
}

function Aboutus() {
  const [language,setLanguage] = useState(getLanguage);
  const [contentData, setContentData] = useState();

  const updateLanguageDefined = newLanguage => {
    setLanguage(newLanguage);
  };  
  
  content.init(setContentData);

  let teamGlobe = (contentData,members) => {
    if(contentData && !setupGlobe.globeStarted) {
      const setup = {
        width: Math.min(window.innerWidth,1440 * 0.75), // 75% of container width
        height: 1006,
      };

      initGlobe(setup).then(() => {
        // setupGlobe.partialY = -0.022;
        highilightMemberOnGlobe(members[0]);

        members.forEach((member,idx) => 
          document.getElementById(`member_${idx}`).addEventListener("mouseover",
            () =>  highilightMemberOnGlobe(members[idx])
          )
        );
      }).catch(e => console.error(e));
    }

    setTimeout(() => setStickyGlobe(770,2700),1000);

    return <div id="globeViz"></div>;
  };

  if(!contentData) {
    return <></>;
  }

  const members = teamMembers(contentData,language);

  return (
    <div id="aboutus">
      <LoaderAnimation 
          content={contentData}
      />

      <Header
          content={contentData}
          language={language}
          setLanguage={updateLanguageDefined}
      />

      <section id="InternalContent" className="about">
        <SectionHeader
            Headline_1={contentData["company"]["about"]["Headline_1"][language]}
            Headline_2={contentData["company"]["about"]["Headline_2"][language]}
        />

        <div className="main">
          <div className="container ">
              <article id="description">
                  <div id="DescriptionContent">
                      {DescriptionContent(contentData["company"]["about"]["Description_text_1"],language)}
                      {DescriptionContent(contentData["company"]["about"]["Description_text_2"],language)}
                      {DescriptionContent(contentData["company"]["about"]["Description_text_3"],language)}

                      <div style={{
                        margin: "100px auto -50px",
                        textAlign: "center"
                      }}>
                        <SignIn cta={contentData.company.about.Button[language]} href={contentData.company.about.Button.Relevent_Link}/>
                      </div>
                  </div>
              </article>
          </div>

          <section id="hasTeamDetails">
            <SectionHeader
              Headline_1={contentData["company"]["about"]["Section_1_title"][language]}
              Headline_2={contentData["company"]["about"]["Section_1_title_2"][language]}
            />

            <div id="content">
              <div className="container">
                <article id="hasTeamMembers">
                  <ul id="teamMembers">{
                    members.map((member,idx) => 
                      <li key={idx} className="member" id={`member_${idx}`}>
                        <a href={member.link} target="_blank" rel="noreferrer">
                          <picture>{member.photo && <img data-src={member.photo} alt={`Picture of ${member.name}`} />}</picture>

                          <div className="texts">
                            <h3 className="personName">{member.name}</h3>
                            <h4 className="personTitle">{member.title}</h4>
                          </div>
                        </a>
                      </li>
                    )
                  }</ul>
                </article>
                <article id="hasGlobe">
                  {teamGlobe(contentData,members)}
                </article>
              </div>
            </div>

          </section>
        </div>
      </section>

      <Footer
          content={contentData}
          language={language}
      />
    </div>
  );
}

export default Aboutus;