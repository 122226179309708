import React from "react";
import S3Image from "../../helpers/S3Image";
import waitForMs from "../../helpers/waitForMs";

import IntroContent from "../IntroContent/IntroContent";

import "./Intro.scss";

let justifyContentIsChanged = false;
// @pickSentenceInParts
// this is done because Intelligence goes in different positions depending of
// the language, so I do break the text based on where it is
function pickSentenceInParts(sentence,Headline_break) {
  const brokenSentence = sentence.split(Headline_break); 
  const valueToFilter = ""; // it filters by empty string because split also removes the value used to separate the string 

  const contentToType = brokenSentence.findIndex(i => i != valueToFilter);
  const contentToKeep = brokenSentence.findIndex(i => i == valueToFilter);

  const letters = brokenSentence[contentToType].split("");

  const finalContent = new Array(brokenSentence.length);

  if((contentToKeep < contentToType) && !justifyContentIsChanged) {
    try {
      document.querySelector("#hasDisclaimer .title").style.justifyContent = "flex-start";
      justifyContentIsChanged = true; // adjust flow of text to avoid bumps when text is being typed/erased
    } catch(e){
      console.log(e);
    }
  }

  return {finalContent,contentToType,contentToKeep,letters};
}
let instance_of_typewritter_exists = false;

async function typeSentence(sentence, eleRef, delay = 100,setIntroText,Headline_break) {
  const {
    finalContent,
    contentToType,
    contentToKeep,
    letters
  } = pickSentenceInParts(sentence,Headline_break);
  let i = 0;

  while(i < letters.length) {
    await waitForMs(delay);

    eleRef += letters[i];
    finalContent[contentToType] = eleRef;
    finalContent[contentToKeep] = Headline_break;

    setIntroText(splitIntroText(finalContent));
    i++;
  }
  return;
}

async function deleteSentence(eleRef, delay = 100,setIntroText,Headline_break) {
  const {
    finalContent,
    contentToType,
    contentToKeep,
    letters
  } = pickSentenceInParts(eleRef,Headline_break);
  
  while(letters.length > 0) {
    await waitForMs(delay);
    letters.pop();
    finalContent[contentToType] = letters.join("");
    finalContent[contentToKeep] = Headline_break;

    setIntroText(splitIntroText(finalContent));
  }
}

async function carousel(introContents, blobContents, setIntroText,blobText,setBlobText,Headline_break) {
  if(!instance_of_typewritter_exists) {
    instance_of_typewritter_exists = true;

    let i = 0;
    let condition = true;

    while(condition) {
      await typeSentence(introContents[i], "",50,setIntroText,Headline_break);

      setBlobText(blobContents[i]);
    
      await waitForMs(6000);
      await deleteSentence(introContents[i],20,setIntroText,Headline_break);
      setBlobText("");

      await waitForMs(500);
      i++;
      if(i >= introContents.length) {i = 0;}
    }
  }
}

function splitIntroText(finalContent) {
  return finalContent.map((w,idx) => <span key={idx}>{w}</span>);
}
function getContent(language,content,introText,setIntroText,blobText,setBlobText) {
  if(!content) 
    return <></>;

  const introContents = [
    content.Introduction.Headline_1[language],
    content.Introduction.Headline_2[language],
    content.Introduction.Headline_3[language],
    content.Introduction.Headline_4[language]
  ];

  const blobContents = [
    content.Introduction.Question_1[language],
    content.Introduction.Question_2[language],
    content.Introduction.Question_3[language],
    content.Introduction.Question_4[language]
  ];

  carousel(
    introContents,
    blobContents,
    setIntroText,
    blobText,
    setBlobText,
    content.Introduction.Headline_break[language]
  );

  const imagePath = S3Image("dist/CITYDATA_san_francisco_3d_waves_2.jpg");

  return (
    IntroContent(imagePath,introText,blobText,content.Introduction.Description_text[language],content,language)
  );
}

function Intro({language,content}) {
  const [introText,setIntroText] = React.useState("");
  const [blobText,setBlobText] = React.useState("");

  return getContent(language,content,introText,setIntroText,blobText,setBlobText);
}

export default Intro;