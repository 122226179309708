import React from "react";

import "./ProductPicture.scss";

function ProductPicture(productDescriptionImage) {
  return (
    <figure className="hasPicture">
      <img data-src={productDescriptionImage || "https://via.placeholder.com/768x500"}
          alt=""
          title=""/>
    </figure>
  );
}

export default ProductPicture;