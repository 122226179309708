import React from "react";

import "./Marketplace.scss";

function Marketplace() {
  return <></>;
  // return (
  //   <article id="marketplace">
  //     <div className="container">
  //       <header>
  //         <h1 id="title">Partners data</h1>
  //       </header>

  //       <div className="content">
  //         <ul>
  //           <li className="offer">
  //             <h2>Factori Raw Location Data | Global mobile location data (1 year history)</h2>
  //             <div className="details">
  //               <div className="owner">
  //                 <div className="name">Factori</div>
  //                 <div className="logo"><img src="https://img-proxy.datarade.ai/x80/https://d3g3pd9iiem0rf.cloudfront.net/public-eu-central-1/providers/acd05de3-b240-4699-8a67-97a132a0fdf2/brand-logo/lifesight_1ca0c41fc21045d1_5652b7754c26ab44.png" alt="" /></div>
  //               </div>

  //               <div className="included">
  //                 <ul>
  //                   <li>226B Monthly Location Pings</li>
  //                   <li>90% Horizontal Accuracy</li>
  //                   <li>248 countries covered</li>
  //                 </ul>
  //               </div>

  //               <div className="description">
  //                 <p>We provide high-quality persistent mobility data from our partnered mobile apps & SDKs and this data feed is aggregated from multiple data sources globally</p>
  //               </div>

  //               <div className="checkout">
  //                 <button>View Product</button>
  //                 <strong>
  //                   <div className="price">$4,500</div>
  //                   <div className="condition">/ month</div>
  //                 </strong>
  //               </div>
  //             </div>
  //           </li>

  //           <li className="offer">
  //             <h2>Factori_Identity Data ( Hashed email linked to unique Id with UID2.0)</h2>
  //             <div className="details">
  //               <div className="owner">
  //                 <div className="name">Factori</div>
  //                 <div className="logo"><img src="https://img-proxy.datarade.ai/x80/https://d3g3pd9iiem0rf.cloudfront.net/public-eu-central-1/providers/acd05de3-b240-4699-8a67-97a132a0fdf2/brand-logo/lifesight_1ca0c41fc21045d1_5652b7754c26ab44.png" alt="" /></div>
  //               </div>

  //               <div className="included">
  //                 <ul>
  //                   <li>1.06B Device to HEM (Hashed Email)</li>
  //                   <li>100% Device to Partner ID Matching</li>
  //                   <li>247 countries covered</li>
  //                 </ul>
  //               </div>

  //               <div className="description">
  //                 <p>Our identity graph data can help brands attach identities to their existing first party data in a safe and privacy compliant manner</p>
  //               </div>

  //               <div className="checkout">
  //                 <button>View Product</button>
  //                 <strong>
  //                   <div className="price">$120,000</div>
  //                   <div className="condition">/ year</div>
  //                 </strong>
  //               </div>
  //             </div>
  //           </li>

  //           <li className="offer">
  //             <h2>Quadrant Mobile Location Data USA - 900+ Million Unique Devices</h2>
  //             <div className="details">
  //               <div className="owner">
  //                 <div className="name">Quadrant</div>
  //                 <div className="logo"><img src="https://img-proxy.datarade.ai/x80/https://d3g3pd9iiem0rf.cloudfront.net/public-eu-central-1/providers/2b956539-2bc7-4d71-95c9-060f1f1da806/brand-logo/quadrant_f53c509a6ca463c1_b766a35082da3454.png" alt="" /></div>
  //               </div>

  //               <div className="included">
  //                 <ul>
  //                   <li>677M Monthly Active Users</li>
  //                   <li>USA covered</li>
  //                   <li>2 years of historical data</li>
  //                 </ul>
  //               </div>

  //               <div className="description">
  //                 <p>Quadrant (An Appen Company) is a global leader in mobile location data, POI data, and corresponding compliance services.</p>
  //               </div>

  //               <div className="checkout">
  //                 <button>View Product</button>
  //                 <strong>
  //                   <div className="condition">Pricing available upon request</div>
  //                 </strong>
  //               </div>
  //             </div>
  //           </li>

  //           <li className="offer">
  //             <h2>Area Visitors -- analyze global foot traffic trends for geographic areas via API or batch data delivery</h2>
  //             <div className="details">
  //               <div className="owner">
  //                 <div className="name">Gravy Analytics</div>
  //                 <div className="logo"><img src="https://img-proxy.datarade.ai/x80/https://d3g3pd9iiem0rf.cloudfront.net/public-eu-central-1/providers/178466a4-5853-4234-a53b-8c6bded2ba2a/brand-logo/gravy-analytics_6e17688cfaa2ea21.jpg" alt="" /></div>
  //               </div>

  //               <div className="included">
  //                 <ul>
  //                   <li>50B global signals per day</li>
  //                   <li>100% deterministic data</li>
  //                   <li>247 countries covered</li>
  //                 </ul>
  //               </div>

  //               <div className="description">
  //                 <p>Submit your own geographic coordinates and time range and receive the counts and mobile device IDs observed in that area during that time in return. </p>
  //               </div>

  //               <div className="checkout">
  //                 <button>View Product</button>
  //                 <strong>
  //                   <div className="price">$1</div>
  //                   <div className="condition"> / API cal</div>
  //                 </strong>
  //               </div>
  //             </div>
  //           </li>
  //         </ul>
  //       </div>
  //     </div>
  //   </article>
  // );
}

export default Marketplace;