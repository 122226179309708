import React, { useState } from "react";

import { useParams } from "react-router-dom";

import "./fonts/Roboto/Roboto-Regular.ttf";
import "./App.scss";

import Header from "./components/Header/Header";

import content from "./helpers/Content";

import Footer from "./components/Footer/Footer";

import TagManager from "react-gtm-module";
import Topic from "./components/Topic/Topic";
import getLanguage from "./helpers/getLanguage";
import summaryContent from "./components/SummaryContent/SummaryContent";
import LoaderAnimation from "./components/LoaderAnimation/LoaderAnimation";

TagManager.initialize({
  gtmId: "GTM-TWZTBF5"
});

function Solutions() {
  const [language,setLanguage] = useState(getLanguage);
  const [contentData, setContentData] = useState();

  const hasEndorser = (contentData) => contentData["solutions"][`${params.topic}`]["Endorser_name"]
                                       && contentData["solutions"][`${params.topic}`]["Endorser_title"]
                                       && contentData["solutions"][`${params.topic}`]["Endorser_text"];
  const updateLanguageDefined = newLanguage => {
    setLanguage(newLanguage);
  };  
  
  content.init(setContentData);

  const params = useParams();
  const hasParams = Object.keys(params).length > 0;

  let contentToDeliver = (contentData,language,params) => {
    let c;

    if(hasParams) {
      let HighlightedCustomerData = null;

      if(contentData) {
        if(hasEndorser(contentData)) {
          HighlightedCustomerData = {
            endorser_name: contentData["solutions"][`${params.topic}`]["Endorser_name"][language],
            endorser_title: contentData["solutions"][`${params.topic}`]["Endorser_title"][language],
            endorser_text: contentData["solutions"][`${params.topic}`]["Endorser_text"][language],
            endorser_photo: contentData["solutions"][`${params.topic}`]["Endorser_name"]["Relevent_Link"]
          };
        }
      }

      c = Topic(
        contentData,
        language,
        "solutions",
        params,
        HighlightedCustomerData
      );
    } else {
      c = summaryContent(
        contentData,
        language,
        "solutions"
      );
    }

    return c;
  };

  return (
      <div className='App'>
          <LoaderAnimation 
              content={contentData}
          />

          <Header
              content={contentData}
              language={language}
              setLanguage={updateLanguageDefined}
          />

          {contentToDeliver(contentData,language,params)}

          <Footer
              content={contentData}
              language={language}
          />
      </div>
  );
}

export default Solutions;
