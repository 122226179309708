import earthDark from "../images/earth-dark.png";
import countries from "../data/ne_110m_admin_0_countries.json";

import addExternalGLobeScript from "./addExternalGLobeScript";

export let setupGlobe = {
    round: 0,
    isGlobeStickyAlready: false,
    GLB: undefined,
    scene: undefined,
    camera: undefined,
    renderer: undefined,
    globeStarted: false,
    globeAnimationStarted: false,
    partialY: 0,
    partialX: 0
};

export function setStickyGlobe(min = 672,max = 3700) {
    if(setupGlobe.isGlobeStickyAlready) 
      return;
    
    try {
      const globeviz = document.getElementById("globeViz");
     
      window.onscroll = function(){    
        if(window.scrollY >= min && window.scrollY < max)
          globeviz.style.transform = `translateY(${window.scrollY - min}px`;
      };
  
      setupGlobe.isGlobeStickyAlready = true;
    } catch(e){
      setupGlobe.isGlobeStickyAlready = false;
    }
}

export function initGlobe(setup) {
    return new Promise((resolve,reject) => {
        if(setupGlobe.globeStarted) {
            reject();
        }

        try {           
            setupGlobe.globeStarted = true;
            addExternalGLobeScript("https://unpkg.com/three").then(() => {
                addExternalGLobeScript("https://unpkg.com/three/examples/js/controls/TrackballControls.js").then(() => {
                    addExternalGLobeScript("https://unpkg.com/three-globe").then(() => {
                        const countries_we_have = [
                            {name: "Australia",amount:0.09072400584733673},
                            {name: "Bangladesh",amount:0.09072400584733673},
                            {name: "Argentina",amount:0.09072400584733673},
                            {name: "Brazil",amount:0.09072400584733673},
                            {name: "Andorra",amount:0.09072400584733673},
                            {name: "Brunei",amount:0.09072400584733673},
                            {name: "United Arab Emirates",amount:0.09072400584733673},
                            {name: "Canada",amount:0.09072400584733673},
                            {name: "Chile",amount:0.09072400584733673},
                            {name: "Spain",amount:0.09072400584733673},
                            {name: "Ecuador",amount:0.09072400584733673},
                            {name: "Costa Rica",amount:0.09072400584733673},
                            {name: "Colombia",amount:0.09072400584733673},
                            {name: "United Kingdom",amount:0.09072400584733673},
                            {name: "India",amount:0.09072400584733673},
                            {name: "Indonesia",amount:0.09072400584733673},
                            {name: "Italy",amount:0.09072400584733673},
                            {name: "Hong Kong",amount:0.09072400584733673},
                            {name: "Japan",amount:0.09072400584733673},
                            {name: "Mexico",amount:0.09072400584733673},
                            {name: "South Korea",amount:0.09072400584733673},
                            {name: "Malaysia",amount:0.09072400584733673},
                            {name: "Thailand",amount:0.09072400584733673},
                            {name: "Taiwan",amount:0.09072400584733673},
                            {name: "Russia",amount:0.09072400584733673},
                            {name: "Singapore",amount:0.09072400584733673},
                            {name: "United States of America",amount:0.09072400584733673},
                            {name: "Saudi Arabia",amount:0.09072400584733673},
                            {name: "South Africa",amount:0.09072400584733673}
                        ];
                    
                        setupGlobe.GLB = new ThreeGlobe(); // eslint-disable-line
                    
                        const countries_admin_features = countries.features.filter(d => countries_we_have.map(c => c.name).includes(d.properties.ADMIN));
                        setupGlobe.GLB
                            .globeImageUrl(earthDark)
                            .polygonsData(countries_admin_features)
                            .polygonCapColor(() => "transparent")
                            .polygonSideColor(() => "rgba(115, 198, 189, 1)")
                            .polygonStrokeColor(() => "#264362")
                            .showGraticules(true);
                        setupGlobe.GLB
                            .polygonAltitude((feature) => countries_we_have.find(item => item.name == feature.properties.ADMIN).amount / 4);
                    
                        setupGlobe.GLB
                            .hexPolygonsData(countries.features)
                            .hexPolygonResolution(3)
                            .hexPolygonMargin(0.6)
                            .hexPolygonColor(() => "rgba(115, 198, 189, 1)")
                            .showAtmosphere(true)
                            .atmosphereColor("rgba(115, 198, 189, 1)")
                            .atmosphereAltitude(0.25);
                    
                    
                        setupGlobe.renderer = new THREE.WebGLRenderer({ alpha: true }); // eslint-disable-line
                        setupGlobe.renderer.setClearColor( 0x000000, 0 );
                
                        if(setup.width > 500) {
                            setupGlobe.renderer.setSize(setup.width, setup.height);
                        } else {
                            setupGlobe.renderer.setSize(setup.width, (setup.height / 2));
                        }
                
                        document.getElementById("globeViz").appendChild(setupGlobe.renderer.domElement);
                    
                        setupGlobe.scene = new THREE.Scene(); // eslint-disable-line
                        setupGlobe.scene.add(setupGlobe.GLB);
                        setupGlobe.scene.add(new THREE.AmbientLight(0xbbbbbb)); // eslint-disable-line
                    
                        setupGlobe.camera = new THREE.PerspectiveCamera(); // eslint-disable-line
                
                        if(setup.width > 500) {
                            setupGlobe.camera.aspect = setup.width/ setup.height;
                        } else {
                            setupGlobe.camera.aspect = setup.width/ (setup.height / 2);
                        }
                
                        setupGlobe.camera.updateProjectionMatrix();
                        setupGlobe.camera.position.z = 290;

                        (function animate() {
                            if(!setupGlobe.globeAnimationStarted) {
                                resolve();
                            }

                            setupGlobe.globeAnimationStarted = true;
                
                            setupGlobe.GLB.rotation.y += setupGlobe.partialY;
                            setupGlobe.GLB.rotation.x += setupGlobe.partialX;
                
                            setupGlobe.renderer.render(setupGlobe.scene, setupGlobe.camera);
                
                            requestAnimationFrame(animate);
                        })();
                    });
                });
            });
        } catch(e){
            reject();
        }
    });
}