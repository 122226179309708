import React from "react";

import "./HighlightedCustomer.scss";
import SVGSpriteImg from "../SVGSpriteImg/SVGSpriteImg";
import S3Image from "../../helpers/S3Image";

function HighlightedCustomer_endorser_photo(HighlightedCustomerData) {
  if(!HighlightedCustomerData 
    || !HighlightedCustomerData.endorser_photo 
    || !HighlightedCustomerData.endorser_photo.includes(".png")
  )
    return <></>;

  return (
    <figure id="endorser_photo">
      <img src={S3Image(`customers/endorsers_photos/dist/${HighlightedCustomerData.endorser_photo}`)}/>
    </figure>
  );
}

function HighlightedCustomer(HighlightedCustomerData) {
  return (
    <div id="HighlightedCustomer">
      <div id="BLOB_Customers">
        <SVGSpriteImg 
          name="BLOB_Customers"
          color="#fd736c"
          size={window.outerHeight / 100 * 60}/>
      </div>

      <div id="HighlightedCustomer_content">
        <div className="hasContent">
          {HighlightedCustomer_endorser_photo(HighlightedCustomerData)}
          <header>
            <hgroup>
              <h3 className="endorser_name">
                {HighlightedCustomerData && HighlightedCustomerData.endorser_name}
              </h3>
              <h4 className="endorser_title">
                {HighlightedCustomerData && HighlightedCustomerData.endorser_title}
              </h4>
              <h5 className="endorser_company">
                {HighlightedCustomerData && HighlightedCustomerData.company}
              </h5>
            </hgroup>
          </header>

          <p className="endorser_text">
            {HighlightedCustomerData && HighlightedCustomerData.endorser_text}
          </p>
        </div>
      </div>
    </div>
  );
}

export default HighlightedCustomer;