import React from "react";

// import DOMPurify from "dompurify";
import "./Topic.scss";

import imagePathToS3 from "../../helpers/imagePathToS3";

import contentSteps from "../../helpers/contentSteps";
// import tableTexts from "../../helpers/tableTexts";
// import descriptionTexts from "../../helpers/descriptionTexts";
import ProductDescription from "../ProductDescription/ProductDescription";
import IntroContent from "../IntroContent/IntroContent";

import HighlightedCustomer from "../HighlightedCustomer/HighlightedCustomer";
import DescriptionContent from "../DescriptionContent/DescriptionContent";
// import internalSectionsContent from "../../helpers/internalSectionsContent";
// import SectionHeader from "../SectionHeader/SectionHeader";

function customImagery(image) {
    return <figure className="hasImage">
        <img data-src={imagePathToS3(image)}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = image;
            }}/>
    </figure>;
}
// function sectionContent(key,title,description,image,content,language,table) {
//     return (<li className="section" key={key}>
//       <ProductDescription 
//           Headline_1={title}
//           descriptionTexts={descriptionTexts(content,language)}
//           tableTexts={table}
//           imagery={customImagery(image)}
//       />
//     </li>);
// }
// function getSections(content,language) {
//     return internalSectionsContent(content,language).map((section,key) => 
//         sectionContent(
//             key,
//             section.title,
//             section.description,
//             section.image,
//             content,
//             language,
//             section.table
//         )
//     );
// }
function getSolution(contentData,language,section,params,HighlightedCustomerData = null) {
    if(!contentData) 
      return <></>;
    
    const sections = {};
    const content = contentData[`${section}`][`${params.topic}`];
    const contentKeys = Object.keys(content);
    const showCasesLinks = [];
    const productsLinks = [];

    [...new Set(
        Object.keys(content)
            .filter(k => k.includes("Section"))
            .map(sections => sections.split("_")[1])
            .map(sectionNumber => `Section_${sectionNumber}_`)
    )].forEach(section => {
        sections[section] = {};
        contentKeys
            .filter(tk => tk.includes(section))
            .forEach(tk => sections[section][tk] = content[tk]);
    });

    if(contentData){
        const showcases = {};
        const products = {};

        Object.keys(content).forEach(key => {
            if(key.includes("Showcase_")) {
                const showcaseIDX = key.split("_")[1];

                if(!showcases[`Showcase_${showcaseIDX}`]) {
                    showcases[`Showcase_${showcaseIDX}`] = {
                        "title":"",
                        "image":"",
                        "dashboard":"",
                        "description":""
                    };
                }

                showcases[`Showcase_${showcaseIDX}`][key.split("_").reverse()[0]] = content[key].English || content[key].Relevent_Link;
            }

            if(key.includes("Product_")) {
                const key_pieces = key.split("_");
                const productIDX = key_pieces[1];

                if(!products[`Product_${productIDX}`]) {
                    products[`Product_${productIDX}`] = {};
                }

                products[`Product_${productIDX}`][key_pieces.pop()] = content[key][language] || content[key].Relevent_Link;
            }
        });

        const showcasesValues = Object.values(showcases);
        showcasesValues.forEach(showcase => showCasesLinks.push(<li className="showcase">
            <a href={showcase.dashboard}
               target="blank"
            >
                <img src={showcase.image}/>
                <h3>{showcase.title}</h3>
                <h4>{showcase.description}</h4>
            </a>
        </li>));

        productsLinks.push(...Object.values(products).filter(product => product.name));
      }

    return (<section id="Solution" className={params.topic}>
        {IntroContent(
            content["Blob"]["Relevent_Link"],
            content["Headline_1"][language],
            content["Headline_2"][language],
            "",
            contentData,
            language
        )}

        <div className="main">
            <div>
                <article id="description" className="container">
                    <div id="DescriptionContent">
                        {DescriptionContent(content["Description_text_1"],language)}
                        {DescriptionContent(content["Description_text_2"],language)}
                        {DescriptionContent(content["Description_text_3"],language)}
                    </div>
                    {HighlightedCustomerData ? <div id="hasHighlightedCustomer">
                        {HighlightedCustomer(HighlightedCustomerData)}</div>
                        : <></>
                    }
                </article>

                {content["Product_headline"] && productsLinks.length ? 
                <article id="matching_products">
                    <header className="SectionHeader">
                        <div className="container">
                            <hgroup>
                                <div className="headlineAndButton">
                                    <h1 className="Headline_1">{content["Product_headline"][language]}</h1>
                                </div>
                            </hgroup>
                        </div>
                    </header>

                    <div className="container">
                        <ul className="hasProducts">
                            {productsLinks.map((product,index) => 
                                <li className="product" key={index}>
                                    <article>
                                        <h1>
                                            <img src={product.image} alt={product.name} />
                                            {product.name}
                                        </h1>
                                        <p>{product.description}</p>
                                    </article>
                                </li>
                            )}
                        </ul>
                    </div>
                </article>
                : <></> }

                <article id="showcases">
                    <header className="SectionHeader">
                        <div className="container">
                            <hgroup>
                                <div className="headlineAndButton">
                                    <h1 className="Headline_1">{content["Showcases_title"][language]}</h1>
                                </div>

                                {/* <h2 className="Headline_2">{content.Showcases_description}</h2> */}
                            </hgroup>
                        </div>
                    </header>            

                    <ul id="showcasesList">
                        {showCasesLinks}
                    </ul>
                </article>

                <div className="divisor"></div>

                <ol id="sections">
                    {
                        Object.values(sections).map((section,idx) => {                           
                            return <li className="section" key={idx}>
                                <ProductDescription 
                                    Headline_1={section[Object.keys(section).find(k => k.includes("title") && !k.includes("_Step_"))][language]}
                                    descriptionTexts={Object.keys(section)
                                        .filter(key => key.includes("_Description_text_"))
                                        .map(key => section[key][language])
                                    }
                                    tableTexts={Object.keys(section)
                                        .filter(key => key.includes("_Table_text"))
                                        .map(key => section[key][language])
                                    }
                                    imagery={Object.keys(section)
                                        .filter(key => key.includes("_Image_path"))
                                        .map(key => customImagery(section[key]["Relevent_Link"]))
                                    }
                                    steps={contentSteps(section,language)}
                                />
                            </li>;
                        })
                    }
                </ol>
            </div>
        </div>
    </section>);
}

export default getSolution;