import React from "react";

function formatValue(targetCityData,field,units,header,item) {
    const fieldIdentifier = `${header} - ${item}`;

    const value = targetCityData[fieldIdentifier];

    let adjustedValue = value;
  
    if(typeof value === "number") {
      adjustedValue = new Intl.NumberFormat("en-US").format(value);
    }
  
    let toReturn = `${adjustedValue} ${units[fieldIdentifier]}`;
    if(!adjustedValue) {
      toReturn = "";
    }

    return toReturn;
}
  
function targetCityDetails(targetCity,units,content,language,contentSearchSections) {
    if(!content) 
      return <></>;

    return (
      <div id="citiesDetails">
        <div className="topic">
          <table>
            <tbody>
              {
                contentSearchSections.map(section => {
                  return (<>
                    <tr><td colSpan={2}>
                      <h2>
                        {section.header}
                        <strong>{section.description}</strong>
                      </h2>
                    </td></tr>
                    {
                      section.items ? section.items.map((item,idx) => {
                        return <tr key={idx}>
                          <td>{item}</td>
                          <td>{formatValue(targetCity,"Administrative Data - City Metro Area",units,section.header,item)}</td>
                        </tr>;
                      }) : <></>
                    }
                  </>);
                })
              }
            </tbody>
          </table>
        </div>
      </div>    
    );
}

export default targetCityDetails;