import React from "react";

import auth from "../../helpers/auth";

import "./Header.scss";

function scrollTopIfNeeded() {
  if(window.location.href.includes("#")){
    let hash = window.location.href.split("#")[1].toLowerCase();

    if(document.getElementById(hash)){
        scrollTo(0, document.getElementById(hash).offsetTop);
    }
  }  
}
function updateLanguage(newLanguage,setLanguage) {
    setLanguage(newLanguage);
    window.localStorage.setItem("language",newLanguage);
    location.reload();
} 
function validDropdownItems (menuParentItem) {
  return menuParentItem.items;
}
function translations(setLanguage,content) {
  return (
    <li className="hasDropdown languages">
      <a href="#" target="_self" title="Company" className="cta ">大/A</a>
      <ul className="dropdown">
        {Object.values(content._languages).sort((a,b) => b.enabled - a.enabled).map(language => <li className={`dropdown_item ${!language.enabled ? "disabled" : ""}`} key={language.code}>
            <a onClick={function () { updateLanguage(language.language,setLanguage); }}
              title={language.language}>
              {language.language}
            </a>
          </li>
        )}
      </ul>
    </li>
  );
}
function linkComponent(item,isRootCTA,hasDropdown) {
  return <a
    href={item.href}
    target={item.target}
    title={item.title}
    className={`${isRootCTA ? "cta" : ""} ${item.class}`}
    onClick={function(e){
      if(window.innerWidth <= 1000 && hasDropdown) {
        e.preventDefault();
        e.target.parentNode.classList.toggle("active");
      }
    }}
  >
  {item.content}
  </a>;
}

function dropdown(menuOBJ,key) {
  const dropdown_items = validDropdownItems(menuOBJ[key]);

  return <ul
      className="dropdown"
    >
    {dropdown_items
      .filter(item => item["Has_content"])
      .sort((item_a,item_b) => item_b["Has_content"] - item_a["Has_content"])
      .map(item => {
        let itemClassName = "dropdown_item";

        if(!item["Has_content"]) {
          itemClassName += " disabled";
        }

      return <li key={item.key} className={itemClassName}>
        {linkComponent(item,false)}
      </li>;
    })}
  </ul>;
}

function setLinkDataComponents(key,obj,language) {
  return {
    Has_content: obj["Has_content"], 
    key: key, 
    content: obj[language],
    href: obj["Relevent_Link"],
    target: obj["Target"] ? obj["Target"] : "_self",
    title: obj["Link_Title"] || obj[language],
    class: obj["Link_Class"],
    items: []
  };
}

function buildMenuItems(content,language) {  
  try {
    const menuOBJ = {};
    const navItemsKeys = Object.keys(content["Navigation"]);
    
    navItemsKeys
      .filter(k => 
        k.includes("Menu_") && k.split("_").length == 2
      ) // pick only items on first level of menu, that contains a single '_'
      .forEach(key => {
        menuOBJ[key] = setLinkDataComponents(key,content["Navigation"][key],language);

        navItemsKeys.filter(item => 
          item.includes(key) && item.split("_").length > 2
        )
        .forEach(item => 
          menuOBJ[key].items.push(
            setLinkDataComponents(item,content["Navigation"][item],language)
          )
        );
      });

    return <> {
      Object.keys(menuOBJ)
        .map((key) => {
          const hasDropdown = menuOBJ[key].items.length > 0 && 
            validDropdownItems(menuOBJ[key]).length > 0;

          return (
            <li
                className={hasDropdown ? "hasDropdown" : ""}
                key={key}
              >
              {linkComponent(menuOBJ[key],true,hasDropdown)}
              {hasDropdown ? dropdown(menuOBJ,key) : <></>}
            </li>                
          );
        })
    } </>;
  } catch (error) {
    console.log("error",error);
  }

  return <></>;
}

function navMenu(content,language,setLanguage) {
  return <>
    <menu>
      <ul>
        {buildMenuItems(content,language)}
        {translations(setLanguage,content)}
      </ul>
    </menu>
  </>;
}

function getContent(content,language,setLanguage) {
  if(!content) 
    return <></>;

  setTimeout(scrollTopIfNeeded,1000);

  return (
    <header id="Header" className={`App-header ${auth.isLogged() ? "isLogged" : ""}`}>
      <div className="container">
        <h1 id="logo">
          <a href="/"> CITYDATA.ai </a>

          <button className="toggleMenu onMobile"
            onClick={function(){
              let menu = document.getElementsByTagName("MENU");
              const body = document.querySelector("body");
              if(menu && menu[0]) {
                menu[0].classList.toggle("opened");
                body.classList.toggle("freeze");
              }
            }}>
            =
          </button>
        </h1>

        {navMenu(content,language,setLanguage)}
      </div>
    </header>
  );
}

function Header({content,language,setLanguage}) {
  return getContent(content,language,setLanguage);
}

export default Header;