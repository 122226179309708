import React from "react";

import "./OptIn.scss";

import CookieConsent from "react-cookie-consent";

function getOptIn(language,content) {
  if(!content) 
    return <></>;

  return (
    <CookieConsent
        location="bottom"
        buttonText={content.OptIn.buttonText[language]}
        cookieName="acceptCityDataAIterms"
        style={{ background: "#2B373B", fontSize: "2rem" }}
        buttonStyle={{ color: "#4e503b", fontSize: "2rem" }}
        expires={150}
      >
      {content.OptIn.content[language]}
    </CookieConsent>
  );
}

function OptIn({language,content}) {
  return getOptIn(language,content);
}

export default OptIn;