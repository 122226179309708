import React from "react";

// import SliderControls from "../SliderControls/SliderControls";

import "./Citychat.scss";
import ProductDescription from "../ProductDescription/ProductDescription";
import tableTexts from "../../helpers/tableTexts";
import descriptionTexts from "../../helpers/descriptionTexts";
import S3Image from "../../helpers/S3Image";
import playSlider from "../../helpers/playSlider";

function customImagery() {
  const images = [
    S3Image("dist/CITYDATA_CITYCHAT_screen_2.png"),
    S3Image("dist/CITYDATA_CITYCHAT_screen_3.png"),
    S3Image("dist/CITYDATA_CITYCHAT_screen_4.png"),
    S3Image("dist/CITYDATA_CITYCHAT_screen_5.png"),
    S3Image("dist/CITYDATA_CITYCHAT_screen_6.png"),
    S3Image("dist/CITYDATA_CITYCHAT_screen_7.png"),
    S3Image("dist/CITYDATA_CITYCHAT_screen_8.png"),
    S3Image("dist/CITYDATA_CITYCHAT_screen_9.png")    
  ];

  playSlider("#citychat",images.length);

  return (
    <div className="devices">
      <div className="phone">
        <div className="phoneMockup">
        {/* {SliderControls(images,slide2Position,setSlide2Position)} */}
          <div className="collectionHolder">
              <div className="collection">
                <div className="images">
                  <div className="scroller">
                  {images.map((imgSrc,idx) => 
                    <figure className="hasImage toSlide" key={idx}>
                      <img className="citydashImage" data-src={imgSrc} alt="" />
                    </figure>
                  )}
                  </div>
                </div> {/* .collection */}            
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function getContent(language,content) {
  if(!content) 
    return <></>;
    
  return (
    <div id="citychat" className="hasSectionContent">
      <ProductDescription 
          Headline_1={content.CityChat.Headline_1[language]}
          Headline_2={content.CityChat.Headline_2[language]}
          Button={content.CityChat.Button[language]}
          ButtonHref={content.CityChat.Button["Relevent_Link"]}
          descriptionTexts={descriptionTexts(content.CityChat,language)}
          tableTexts={tableTexts(content.CityChat,language)}
          imagery={customImagery()}
          SectionHeaderLogoImagePath="https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/citychat_cc_blob_2letter.png"
          SectionHeaderLogoImageDescription="CityChat badge logo"
        />
    </div>
  );
}

function Citychat({language,content}) {
  return getContent(language,content);
}

export default Citychat;