import React, { useState } from "react";

import {ReactComponent as Logo} from "../../images/svg/symbol.svg";
import "./LoaderAnimation.scss";

let imagesLoaded = false;
function loadLazyImages() {
  if(!imagesLoaded) {
    const dataSrcImages = [...document.querySelectorAll("img[data-src]")];

    // if(dataSrcImages.length == 0) {
      // imagesLoaded = true;
    // }

    dataSrcImages.filter(img => !img.src && img.getAttribute("data-src"))
        .sort((imgA,imgB) => imgB.getAttribute("data-src").includes("customer") - imgA.getAttribute("data-src").includes("customer"))
        .forEach(img => {
          img.src = img.getAttribute("data-src");
          img.removeAttribute("data-src");
        });
  }
}

// let body;
let logo;
let isCleared = false;
function clearAnimation() {
  if(!isCleared) {
    isCleared = true;

    logo.style.fill = "rgb(247, 105, 99)";
      
    const loaderElement = document.querySelector("#LoaderAnimation");
    loaderElement.classList.add("out");
  
    setTimeout(() => 
      loaderElement.parentNode.removeChild(loaderElement),
    900);  
  }
}

function animate(contentData){
  // body = document.querySelector("body");
  // body.style.overflow = "hidden";
  logo = document.querySelector("#LoaderAnimation #hasIntroLogo svg path");

  let x = 0;
  let isDataReady = false;
  let isGlobeReady = false;
  let loaderAnimation = setInterval(() => {
      x = x + 3;
      if(logo)
        logo.style.strokeDashoffset = `${x}px`;

      isDataReady = contentData && typeof contentData.content != "undefined";
      isGlobeReady = window.rendered;

      if(isDataReady) {
        loadLazyImages();
        clearAnimation();

        if(isGlobeReady) {
          // body.style.overflow = "auto";
          clearInterval(loaderAnimation);
        }
      }
  }, 1);
}

function LoaderAnimation(contentData) {
  const [displayIntro] = useState(true);

  setTimeout(() => animate(contentData),10);

  return (
    <div id="LoaderAnimation">
      {displayIntro ?  <div id="hasIntroLogo"><Logo /></div> : <></>}
    </div>
  );
}

export default LoaderAnimation;