const topicsConverter = {
    parks_and_recreation: "parks"
};
function  getRSS(topic = null) {
    return new Promise((resolve,reject) => {
        const domain = "https://blog.citydata.ai/";
        const rss2json = "https://api.rss2json.com/v1/api.json?rss_url=";
        let univercityPath = `${domain}rss/`;
    
        if(topic) {
            univercityPath = `${domain}tag/${topicsConverter[topic]}/rss/`;
        }
    
        const endpoint = `${rss2json}${univercityPath}`;
        const options = {
            "referrerPolicy": "strict-origin-when-cross-origin",
            "body": null,
            "method": "GET",
            "mode": "cors",
            "credentials": "omit"
        };

        fetch(endpoint, options)
            .then(content => content.json())
            .then(content => resolve(content.items))
            .catch(error => reject(error));
    });
}

export default getRSS;