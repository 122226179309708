import React, { useState } from "react";

import "./fonts/Roboto/Roboto-Regular.ttf";
import "./App.scss";
import "./SearchPage.scss";

import OptIn from "./components/OptIn/OptIn";

import LoaderAnimation from "./components/LoaderAnimation/LoaderAnimation";
import Header from "./components/Header/Header";
import Search from "./components/Search/Search";

import content from "./helpers/Content";

import Footer from "./components/Footer/Footer";

import TagManager from "react-gtm-module";
import getLanguage from "./helpers/getLanguage";
TagManager.initialize({
  gtmId: "GTM-TWZTBF5"
});

function SearchPage() {
  const [language,setLanguage] = useState(getLanguage);
  const [contentData, setContentData] = useState();
  
  content.init(setContentData);

  return (
        <>
            <div className='SearchPage'>
                <LoaderAnimation 
                    content={contentData}
                />

                <OptIn
                    content={contentData}
                    language={language}
                />

                <Header
                    content={contentData}
                    language={language}
                    setLanguage={setLanguage}
                />

                <Search
                    content={contentData}
                    language={language}
                />

                <Footer
                    content={contentData}
                    language={language}
                />
            </div>
        </>
  );
}

export default SearchPage;