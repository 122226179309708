import React, { useState } from "react";

import "./fonts/Roboto/Roboto-Regular.ttf";
import "./App.scss";

import Header from "./components/Header/Header";

import content from "./helpers/Content";

import Footer from "./components/Footer/Footer";

import TagManager from "react-gtm-module";
import SectionHeader from "./components/SectionHeader/SectionHeader";
import getLanguage from "./helpers/getLanguage";
import LoaderAnimation from "./components/LoaderAnimation/LoaderAnimation";

TagManager.initialize({
  gtmId: "GTM-TWZTBF5"
});

function Solutions() {
  const [language,setLanguage] = useState(getLanguage);
  const [contentData, setContentData] = useState();

  const updateLanguageDefined = newLanguage => {
    setLanguage(newLanguage);
  };  
  
  content.init(setContentData);

  return (
      <div className='App'>
          <LoaderAnimation 
              content={contentData}
          />

          <Header
              content={contentData}
              language={language}
              setLanguage={updateLanguageDefined}
          />

          <section id="InternalContent" className="partner_policies">
              <SectionHeader
                  Headline_1="Partner Policies"
                  Headline_2="Effective Date: September 20, 2021"
              />

              <div className="container main">
                  <div>
                      <article id="description">
                          <div id="DescriptionContent">
                            <ol style={{marginLeft: "25px"}}>
                              <li>
                                <a href="https://s3.us-west-2.amazonaws.com/lotadata.media/citydash/san_leandro/San_Leandro_Police_Department_Crime_Map_FAQ.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"> 
                                  SLPD Calls for Service Data FAQ 
                                </a>
                              </li>
                              <li>
                                <a href="https://s3.us-west-2.amazonaws.com/lotadata.media/citydash/san_leandro/San_Leandro_Public_DashBoard_Disclaimer.pdf"
                                   target="_blank"
                                   rel="noopener noreferrer"> 
                                  City Of San Leandro - Terms of Use 
                                </a>
                              </li>
                            </ol>
                          </div>
                      </article>
                  </div>
              </div>
          </section>

          <Footer
              content={contentData}
              language={language}
          />
      </div>
  );
}

export default Solutions;
