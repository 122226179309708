import React from "react";
import ProductDescription from "../ProductDescription/ProductDescription";

import descriptionTexts from "../../helpers/descriptionTexts";
import "./Privacy.scss";
import S3Image from "../../helpers/S3Image";
import ProductPicture from "../ProductPicture/ProductPicture";

function getContent(language,content) {
  if(!content) 
    return <></>;

  return (
    <section id="Privacy" className="hasSectionContent">
      <ProductDescription 
          Headline_1={content.Privacy.Headline_1[language]}
          Headline_2={content.Privacy.Headline_2[language]}
          Button={content.Privacy.Button[language]}
          ButtonHref={content.Privacy.Button["Relevent_Link"]}
          descriptionTexts={descriptionTexts(content.Privacy,language)}
          imagery={ProductPicture(S3Image("dist/CITYDATA_PRIVACY_Image_1.png"))}
          tableTexts={null}
      />
    </section>
  );
}

function Privacy({language,content}) {
  return getContent(language,content);
}

export default Privacy;