import unique from "./unique";

const internalSectionsContent = (content,language) => {
    const items = [];

    const keys = Object.keys(content);

    keys
      .filter(key => key.includes("Section_"))
      .map(key => {
        let array = key.split("_");
  
        array.pop();
  
        return array.join("_");
      })
      .filter(unique)
      .sort((a,b) => parseInt(a.replace("Section_","")) - parseInt(b.replace("Section_","")))
      .filter(key => !key.includes("Table_text"))
      .forEach(key => {
        let title = null;
        if(content && content[`${key}_title`] && content[`${key}_title`][language]) {
          title = content[`${key}_title`][language];
        }

        let description = null;
        if(content && content[`${key}_description`] && content[`${key}_description`][language]) {
          description = content[`${key}_description`][language];
        }

        let image = null;
        if(content && content[`${key}_image`] && content[`${key}_image`]["Relevent_Link"]) {
          image = content[`${key}_image`]["Relevent_Link"];
        }

        let table = null;
          if(keys) {
            table = keys
            .filter(i => i.includes(`${key}_Table_text_`))
            .sort((a,b) => parseInt(a.split("_").pop()) - parseInt(b.split("_").pop()))
            .map(x => content[x][language]);
          }

        items.push({
          title: title,
          description: description,
          image: image,
          table: table
        });
      });
    return items;
};

export default internalSectionsContent;