import React from "react";
import unique from "../../helpers/unique";

import S3Image from "../../helpers/S3Image";
import SectionHeader from "../SectionHeader/SectionHeader";

import "./Pricing.scss";

const plans = (content,language) => {
  const plans = [];

  Object.keys(content)
    .filter(key => key.includes("Image_"))
    .map(key => {
      let array = key.split("_");

      array.pop();

      return array.join("_");
    })
    .filter(unique)
    .map(key => {
      let obj = {};

      if(content[`${key}_pricing`] && content[`${key}_pricing`][language] != null){
        obj.pricing = content[`${key}_pricing`][language];
      }

      if(content[`${key}_title`] && content[`${key}_title`][language] != null){
        obj.title = content[`${key}_title`][language];
      }

      if(content[`${key}_title_2`] && content[`${key}_title_2`][language] != null){
        obj.title_2 = content[`${key}_title_2`][language];
      }

      if(content[`${key}_subtitle`] && content[`${key}_subtitle`][language] != null){
        obj.subtitle = content[`${key}_subtitle`][language];
      }

      if(content[`${key}_path`] && content[`${key}_path`]["Relevent_Link"]){
        obj.path = S3Image(`dist/${content[`${key}_path`]["Relevent_Link"]}`);
      }

      if(obj.pricing != null && obj.title != null && obj.title_2 != null && obj.subtitle != null && obj.path != null) {
        plans.push(obj);
      }
    });
  return plans;
};

function getContent(language,content) {
  if(!content) 
    return <></>;

  return (
    <section id="Pricing" className="hasSectionContent">
      <SectionHeader
        Headline_1={content.Pricing.Headline_1[language]}
        Headline_2={content.Pricing.Headline_2[language]}
        Button={content.Pricing.Button[language]}
        ButtonHref={content.Pricing.Button["Relevent_Link"]} />

      <div className="container">
        <article id="Prices">
          <ul className="hasPlans">
            {plans(content.Pricing,language).map((plan,index) => 
              <li className="plan" key={index}>
                <figure className="hasImage">
                  <img data-src={plan.path} alt="" />
                </figure>
                <p className="pricing">${plan.pricing}</p>
                <h5 className="title">{plan.title}</h5>
                <h6 className="title_2">{plan.title_2}</h6>
                <h4 className="subtitle">{plan.subtitle}</h4>
            </li>)}
          </ul>
        </article>
      </div>
    </section>
  );
}

function Pricing({language,content}) {
  return getContent(language,content);
}

export default Pricing;