import React from "react";

import "./CityAI.scss";
import tableTexts from "../../helpers/tableTexts";
import descriptionTexts from "../../helpers/descriptionTexts";
import S3Image from "../../helpers/S3Image";
import ProductPicture from "../ProductPicture/ProductPicture";
import SectionHeader from "../SectionHeader/SectionHeader";
import imageTexts from "../../helpers/imageTexts";
import symbol from "../../images/svg/symbol.svg";

function getContent(language,content) {
  if(!content) 
    return <></>;

  const productsPictures = [
    S3Image("dist/CITY_APPS.png"),
    S3Image("dist/CITY_AI.png"),
    S3Image("dist/CITYDASH.png")
  ];

  let imgTexts = imageTexts(content.CityAI,language);
  let descTexts = descriptionTexts(content.CityAI,language);
  let tablTexts = tableTexts(content.CityAI,language);
  return (
    <div id="cityai" className="hasSectionContent">
      <div className="ProductDescription">
        <SectionHeader
          Headline_1={content.CityAI.Headline_1[language]}
          Headline_2={content.CityAI.Headline_2[language]}
          Button={content.CityAI.Button[language]}
          ButtonHref={content.CityAI.Button["Relevent_Link"]}
          SectionHeaderLogoImagePath="https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/cityai_ca_blob_2_letter.png"
          SectionHeaderLogoImageDescription="CityAI badge logo"/>

        <div className="container">
          <section>
              <div>
                <article className="main">
                  <div className="hasContent">
                    <div>
                      {descTexts && descTexts.map((dt,index) => <p  key={index}>{dt}</p>)}
                    </div>
                  </div>
                </article>
              </div>

              <div>
                  <ul id="hasDescriptions">
                    {imgTexts && imgTexts.map((img,index) => <li className="item" key={index}>
                      <article>
                        {ProductPicture(productsPictures[index])}

                        <h2 className="title">{img.title}</h2>
                        <h3 className="subtitle">{img.subtitle}</h3>
                      </article>  
                    </li>)}
                  </ul>
              </div>

            <article className="features">
              <ul className="hasFeatures">
                {tablTexts && tablTexts.map((feature,index) => 
                <li className="feature" key={index}>
                  <img data-src={symbol} alt="CITYDATA.ai Symbol" className="symbol" />{feature}
                </li>)}
              </ul>
            </article>
          </section>
        </div>
      </div>
    </div>
  );
}

function CityAI({language,content}) {
  return getContent(language,content);
}

export default CityAI;