import React from "react";

import SignIn from "../SignIn/SignIn";
import "./SectionHeader.scss";

function SectionHeader({Headline_1,Headline_2,Button,ButtonHref, SectionHeaderLogoImagePath, SectionHeaderLogoImageDescription}) {
  return (
    <header className="SectionHeader">
        <div className="container">
          <hgroup>
              <div className="headlineAndButton">
                <h1 className="Headline_1">
                  {SectionHeaderLogoImagePath ? <img src={SectionHeaderLogoImagePath} alt={SectionHeaderLogoImageDescription} className="SectionHeaderLogoImage"/> : <></>}
                  {Headline_1}
                </h1>
                {Button ? <SignIn cta={Button} href={ButtonHref}/> : ""}
              </div>
              {Headline_2 ? <h2 className="Headline_2">
                {Headline_2}
              </h2> : ""}
          </hgroup>
        </div>
      </header>
  );
}

export default SectionHeader;