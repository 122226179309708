function inactiveImage(holderElement, lastImageIndex){
    holderElement.querySelectorAll(".hasImage")[lastImageIndex]
        .classList.remove("active");
}
function activeImage(holderElement, currentImageIndex){
    holderElement.querySelectorAll(".hasImage")[currentImageIndex]
        .classList.add("active");
}

function playSlider(holderSection, imagesLength){
    const selector = `${holderSection} .collectionHolder .collection .images`;
    let holderElement = null;
    let current = 0;
    let next = 1;


    const waitForHolder = setInterval(() => {
        holderElement = document.querySelector(selector);

        if(holderElement) {
            clearInterval(waitForHolder);
            activeImage(holderElement, current);
        }
    },1);

    setInterval(() => {
        inactiveImage(holderElement, current);
        activeImage(holderElement, next);

        current = Math.round(next);
        next = next + 1;

        if(next == imagesLength) {
            next = 0;
        }
    },5000);
}

export default playSlider;