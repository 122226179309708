import React from "react";

import "./CitySDK.scss";
import ProductDescription from "../ProductDescription/ProductDescription";
import tableTexts from "../../helpers/tableTexts";import descriptionTexts from "../../helpers/descriptionTexts";
import S3Image from "../../helpers/S3Image";
import ProductPicture from "../ProductPicture/ProductPicture";

function getContent(language,content) {
  if(!content) 
    return <></>;

  return (
    <div id="citysdk" className="hasSectionContent">
      <ProductDescription 
          Headline_1={content.CitySDK.Headline_1[language]}
          Headline_2={content.CitySDK.Headline_2[language]}
          Button={content.CitySDK.Button[language]}
          ButtonHref={content.CitySDK.Button["Relevent_Link"]}
          descriptionTexts={descriptionTexts(content.CitySDK,language)}
          imagery={ProductPicture(S3Image("dist/CITYDATA_SDK_Image_1.png"))}
          tableTexts={tableTexts(content.CitySDK,language)}
          SectionHeaderLogoImagePath="https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/citysdk_cs_blob_2_letter.png"
          SectionHeaderLogoImageDescription="CitySDK badge logo"
      />
    </div>
  );
}

function CitySDK({language,content}) {
  return getContent(language,content);
}

export default CitySDK;