import React from "react";
import ReactDOM from "react-dom";

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import "./index.scss";

import Aboutus from "./components/Aboutus/Aboutus";
import Company from "./Company";
import Privacy from "./Privacy";
import Solutions from "./Solutions";
import PartnerPolicies from "./PartnerPolicies";
import SearchPage from "./SearchPage";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import FreeConsultation from "./components/FreeConsultation/FreeConsultation";

ReactDOM.render(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}></Route>
          <Route path="/solutions/:topic" element={<Solutions />} />
          <Route path="/privacy/:topic" element={<Privacy />} />
          <Route path="/company/:topic" element={<Company />} />
          <Route path="/company/about" element={<Aboutus />} />
          <Route path="/partner_policies" element={<PartnerPolicies />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/free-consultation" element={<FreeConsultation />} />
      </Routes>
    </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
