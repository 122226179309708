import React from "react";

import imagePathToS3 from "../../helpers/imagePathToS3";
import SectionHeader from "../SectionHeader/SectionHeader";

import "./ProductDescription.scss";
import "../steps.scss";

function ProductDescription({ Headline_1, Headline_2, Button, ButtonHref, descriptionTexts, tableTexts, imagery, steps, SectionHeaderLogoImagePath, SectionHeaderLogoImageDescription }) { 
  return (
    <div className="ProductDescription">
      <SectionHeader
        Headline_1={Headline_1}
        Headline_2={Headline_2}
        Button={Button}
        ButtonHref={ButtonHref}
        SectionHeaderLogoImagePath={SectionHeaderLogoImagePath}
        SectionHeaderLogoImageDescription={SectionHeaderLogoImageDescription}/>

      <div className="container">
        <section>
          <article className="main">
            <div className="hasContent">
              <div>
                {descriptionTexts && descriptionTexts.map((dt,index) => <p  key={index}>{dt}</p>)}
              </div>
            </div>

            {imagery}
          </article>

          {steps ? <ul className="hasSteps">
              {Object.values(steps).map((step,index) => {
                const stepText = <>
                  <h3 className="title">{step.head}</h3>
                  <p className="head">{step.title}</p>
                  <p className="subtitle">{step.title_2}</p>
                </>;

                return <li className="step" key={index}>
                  <article>
                    <div>{((index % 2) != 0) && stepText}</div>
                    <div>
                        <img data-src={imagePathToS3(step.path)} 
                              onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = step.path;
                              }}/>
                        {/* <figure className="hasImage"></figure> */}
                    </div>
                    <div>{((index % 2) == 0) && stepText}</div>
                  </article>
                </li>;
              })}
            </ul> : <></>}

          {tableTexts ? <article className="features">
            <ul className="hasFeatures">
              {tableTexts.map((feature,index) => 
              <li className="feature" key={index}>
                <strong>{feature}</strong>
              </li>)}
            </ul>
          </article> : <></>}
        </section>
      </div>
    </div>
  );
}

export default ProductDescription;