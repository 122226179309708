import Papa from "papaparse";

const newLine = `
`;
const adjustContentOfFile = (file) => file.replaceAll("__EOL__",newLine).replaceAll("\"","");
let data = undefined;
const worldwideLocalitiesData = {
    build:(setLocalitiesData) => {
        return new Promise((resolve,reject) => {
            const randomNumber = Math.floor(Math.random() * 200000);
            try {
                if(!worldwideLocalitiesData.data) {
                    fetch(`/static/search/units.csv?cache=${randomNumber}`) // PROD
                    // fetch(`/search/units.csv?cache=${randomNumber}`) // LOCAL
                        .then(res => res.text())
                        .then(unitsCSV => {
                            Papa.parse(adjustContentOfFile(unitsCSV), {
                                header: true,
                                complete: function(parsedUnits) {
                                    fetch(`/static/search/localities.csv?cache=${randomNumber}`) // PROD
                                    // fetch(`/search/localities.csv?cache=${randomNumber}`) // LOCAL
                                        .then(res => res.text())
                                        .then(localitiesCSV => {
                                            Papa.parse(adjustContentOfFile(localitiesCSV), {
                                                header: true,
                                                complete: function(parsedLocalities) {
                                                    worldwideLocalitiesData.data = {
                                                        "NEW LOCALITIES": {},
                                                        "NEW UNITS": {reference: parsedUnits.data[0]}
                                                    };

                                                    parsedLocalities.data.forEach(locality => {
                                                        worldwideLocalitiesData.data["NEW LOCALITIES"][locality.locality_id] = locality;
                                                    });

                                                    setLocalitiesData(worldwideLocalitiesData.data);
                                                    resolve(worldwideLocalitiesData.data);

                                                }
                                            });
                                        });
                                }
                            });
                        });
                } else {
                    resolve(worldwideLocalitiesData.data);
                }
            } catch(error) {
                console.log(error);
                reject(error);
            }
        });
    },
    init: (setLocalitiesData) => {
        return new Promise((resolve,reject) => {
            try {
                worldwideLocalitiesData.build(setLocalitiesData).then((data) => resolve(data));
            } catch(error) {
                reject(error);
            }
        });
    },
    data:data
};
export default worldwideLocalitiesData;