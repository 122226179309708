/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import targetCityDetails from "./targetCityDetails";
import waitForMs from "../../helpers/waitForMs";
import worldwideLocalities from "../../helpers/worldwideLocalities";

import earthDark from "../../images/earth-dark.png";
import countries from "../../data/ne_110m_admin_0_countries.json";

import "./Search.scss";
import SectionHeader from "../SectionHeader/SectionHeader";
import Marketplace from "../Marketplace/Marketplace";

let round = 0;
let partialY = 0;
let partialX = 0;
let cityFinder_input_placeholder = "";
const cityTarget = new URLSearchParams(location.search).get("locality_id");

function inclementCitiesList(localitiesData) {
  const complement = Object.values(localitiesData["NEW LOCALITIES"])
    .filter(c => (typeof c.Lng == "number" && typeof c.Lat == "number"))
    .map(city => ({
      locality_id:city.locality_id,
      name:city["Administrative Data - City County Metro Area"],
      sortBy: Math.random()
    })).sort((a,b) => b.sortBy - a.sortBy);

  initialCities.push(...complement);  
}
function searchSections(contentData,language) {
  const content = contentData["New Search"];

  const sections = [...new Set(Object.keys(content).filter(key => key.includes("Section")).map(k => k.split("_").filter((i,idx) => idx <= 1).join("_")))];
  const sectionsContent = sections.map(section => {
    const sectionKey = String(section);

    const sectionContent = {
        key: sectionKey,
        content: {}
    };


    Object.keys(content).filter(k => k.includes(sectionKey)).map(k => k.replace(`${sectionKey}_`,"")).forEach(key => {
        Object.keys(content)
            .filter(item => item.includes(sectionKey) && item.includes(key))
            .forEach(item => sectionContent.content[key] = content[item][language]);

        sectionContent.content.items = [];
        Object.keys(sectionContent.content).filter(
          contentKey => contentKey.includes("item_")
        ).forEach(item =>
          sectionContent.content.items.push(sectionContent.content[item])
        );
    });

    return sectionContent.content;
  });  

  return sectionsContent;
}

function radFromLat(lat) {
  return lat * (Math.PI / 180);
}
function radFromLng(lng) {
  return -lng * (Math.PI / 180);
}
const initialCities = [
  {locality_id:"san_francisco_county_ca", name:"San Francisco County"},
  {locality_id:"san_jose_ca", name:"San Jose"},
  {locality_id:"puebla_greater_metro_area_mx", name:"Puebla Greater Metropolitan Area"},
  {locality_id:"greater_sao_paulo_metropolitan_area_br", name:"Sao Paulo Greater Metropolitan Area"},
  {locality_id:"greater_london_gb", name:"Greater London Metropolitan Area"},
  {locality_id:"catalonia_metropolitan_region_es", name:"Catalonia Greater Metropolitan Area"},
  {locality_id:"milan_it", name:"Milan"},
  // {locality_id:"riyadh_greater_metropolitan_area", name:"Riyadh"},
  {locality_id:"delhi_in", name:"Delhi National Capital Region"},
  {locality_id:"bangkok_th", name:"Bangkok"},
  {locality_id:"singapore_sg", name:"Singapore"},
  {locality_id:"kuala_lumpur_my", name:"Kuala Lumpur"},
  {locality_id:"jakarta_locality_id", name:"Jakarta"},
  {locality_id:"greater_osaka_metropolitan_area_jp", name:"Greater Osaka Metropolitan Area"},
  {locality_id:"greater_melbourne_metropolitan_area_au", name:"Melbourne"}
];

let cityFinder_input = null;
function highilightCityOnGlobe(foundCity){
  return new Promise(resolve => {
    if(!GLB) {
      let waitForGlobe = setInterval(() => {
        if(GLB) {
          clearInterval(waitForGlobe);

          GLB
            .ringsData([{
              lat: foundCity.Lat,
              lng: foundCity.Lng
            }])
            .ringColor(() => "rgba(255,255,255,1)")
            .ringMaxRadius(6)
            .ringPropagationSpeed(-1)
            .ringRepeatPeriod(1000);
        
          rotateGlobeToTargetCity(foundCity).then(resolve);
        }
      },1);
    } else {
      GLB
        .ringsData([{
          lat: foundCity.Lat,
          lng: foundCity.Lng
        }])
        .ringColor(() => "rgba(255,255,255,1)")
        .ringMaxRadius(6)
        .ringPropagationSpeed(-1)
        .ringRepeatPeriod(1000);
    
      rotateGlobeToTargetCity(foundCity).then(resolve);
    }
  });
}
function rotateGlobeToTargetCity(foundCity) {
  return new Promise((resolve) => {

    const roundsToComplete = 10;
  
    const currentPositionY = Number(GLB.rotation.y);
    const currentPositionX = Number(GLB.rotation.x);
  
    const nextPositionY = radFromLng(foundCity.Lng);
    const nextPositionX = radFromLat(foundCity.Lat);
  
    const diffY = nextPositionY - currentPositionY;
    const diffX = nextPositionX - currentPositionX;
  
    partialY = diffY / roundsToComplete;
    partialX = diffX / roundsToComplete;
  
    (function checkTravelingStatus(){
      if(round == roundsToComplete) {
        partialY = 0;
        partialX = 0;
  
        round = 0;

        resolve();
      } else {
        round = round + 1;
        requestAnimationFrame(checkTravelingStatus);
      }
    })();
    // const transitionInterval = setInterval(() => {
    //   console.log("roundsPerformed",roundsPerformed);
    //   console.log("nextPosition",[nextPositionX,nextPositionY]);
    //   console.log("GLB.rotation",[GLB.rotation.x,GLB.rotation.y]);
    //   if(
    //     (roundsPerformed == roundsToComplete) ||
    //     (GLB.rotation.y == nextPositionY && GLB.rotation.x == nextPositionX)
    //   ) {
    //     partialY = 0;
    //     partialX = 0;

    //     clearInterval(transitionInterval);
    //     resolve();
    //   }

    //   roundsPerformed = roundsPerformed + 1;
    // },roundsToComplete);
  });
}
function eraseTargetCity(cityFinder_input,delay = 100) {
  return new Promise(resolve => {
    (async function(){
      let eraseCount = 0;
      let letters = cityFinder_input.value.split("");
      const cidadeAlvoLength = cityFinder_input.value.length;
      const adjustedDelay = delay / (cidadeAlvoLength * 0.5);
  
      while(eraseCount < cidadeAlvoLength) {
        await waitForMs(adjustedDelay);
        letters.pop();
        cityFinder_input.value = letters.join("");

        if(eraseCount++ == (cidadeAlvoLength - 1)) {
          resolve();          
        }
      }
    })();
  });
}

function typeTargetCity(cityFinder_input,cidadeAlvo,delay = 100) {
  return new Promise(resolve => {
    (async function(){
      let typeCount = 0;
      const cidadeAlvoLength = cidadeAlvo.length;
      const adjustedDelay = delay / (cidadeAlvoLength * 0.5);

      while(typeCount < cidadeAlvoLength) {
        await waitForMs(adjustedDelay);
        cityFinder_input.value = `${cityFinder_input.value}${cidadeAlvo[typeCount++]}`;

        if(typeCount == (cidadeAlvoLength - 1)) {
          resolve();
        }
      }
    })();
  });
}

async function demoCity(x,initialCities,cityFinder_input,localitiesData,setTargetCity) {
  if(initialCitiesDemoCarouselIsStopped) 
    return;

  if(x == initialCities.length) {
    stopDemoCarousel();
    return;
  }

  const cidadeAlvo = initialCities[x];
  const cityToDemo = localitiesData["NEW LOCALITIES"][cidadeAlvo.locality_id];
  
  if(cityToDemo) {
    cityFinder(cidadeAlvo.locality_id,setTargetCity,localitiesData);
    await typeTargetCity(cityFinder_input,cidadeAlvo.name,60);

    await highilightCityOnGlobe(cityToDemo);
  
    await waitForMs(6000);
    await eraseTargetCity(cityFinder_input,30);
  
    demoCity(x+1,initialCities,cityFinder_input,localitiesData,setTargetCity);
  } else {
    demoCity(x+1,initialCities,cityFinder_input,localitiesData,setTargetCity);
  }
}

let initialCitiesDemoCarouselIsStopped = false;
function stopDemoCarousel() {
  initialCitiesDemoCarouselIsStopped = true;
  cityFinder_input.placeholder = cityFinder_input_placeholder;
}
let demonstrationStarted = false;
async function demonstrateCitiesSearch(localitiesData,setTargetCity) {
  if(demonstrationStarted)
    return;

  demonstrationStarted = true;

  cityFinder_input = document.querySelector("#cityFinder_input");

  cityFinder_input_placeholder = cityFinder_input.placeholder;
  cityFinder_input.placeholder = "";

  if(cityFinder_input) {
    if(cityTarget) {
      while(initialCities.length) {
        initialCities.pop();
      }

      cityFinder(cityTarget,setTargetCity,localitiesData);
      const foundCity = localitiesData["NEW LOCALITIES"][cityTarget];
      initialCities.push(
        {locality_id:cityTarget, name:foundCity["Administrative Data - City County Metro Area"]}
      );
      await typeTargetCity(cityFinder_input,initialCities[0].name,60);
    } else {
      setTimeout(() => {
        inclementCitiesList(localitiesData);
        demoCity(0,initialCities,cityFinder_input,localitiesData,setTargetCity);
      },1000);
    }
  }
}

let GLB = undefined;
let scene = undefined;
let camera = undefined;
let renderer = undefined;

let isGlobeStickyAlready = false;
function setStickyGlobe() {
  if(isGlobeStickyAlready) 
    return;
  
  try {
    const min = 672;
    const max = 3700;
    
    const globeviz = document.getElementById("globeViz");
   
    window.onscroll = function(){    
      if(window.scrollY >= min && window.scrollY < max)
        globeviz.style.transform = `translateY(${window.scrollY - min}px`;
    };

    isGlobeStickyAlready = true;
  } catch(e){
    isGlobeStickyAlready = false;
  }
}

async function cityFinder(locality_id,setTargetCity,localitiesData) {
  const foundCity = localitiesData["NEW LOCALITIES"][locality_id];

  if(foundCity) {
    highilightCityOnGlobe(foundCity);
    setTargetCity(foundCity);
    setStickyGlobe();
  }
}

let globeStarted = false;
function addExternalGLobeScript(path) {
  return new Promise(resolve => {
    const script = document.createElement("script");
    script.src = path;
    script.async = true;
    document.body.appendChild(script);

    setTimeout(resolve,2000);
  }); 
}

let globeAnimationStarted = false;
async function initGlobe() {
  if(globeStarted) return;

  globeStarted = true;
  addExternalGLobeScript("https://unpkg.com/three").then(() => {
      addExternalGLobeScript("https://unpkg.com/three-globe").then(() => {
        const countries_we_have = [
            {name: "Australia",amount:0.09072400584733673},
            {name: "Bangladesh",amount:0.09072400584733673},
            {name: "Argentina",amount:0.09072400584733673},
            {name: "Brazil",amount:0.09072400584733673},
            {name: "Andorra",amount:0.09072400584733673},
            {name: "Brunei",amount:0.09072400584733673},
            {name: "United Arab Emirates",amount:0.09072400584733673},
            {name: "Canada",amount:0.09072400584733673},
            {name: "Chile",amount:0.09072400584733673},
            {name: "Spain",amount:0.09072400584733673},
            {name: "Ecuador",amount:0.09072400584733673},
            {name: "Costa Rica",amount:0.09072400584733673},
            {name: "Colombia",amount:0.09072400584733673},
            {name: "United Kingdom",amount:0.09072400584733673},
            {name: "India",amount:0.09072400584733673},
            {name: "Indonesia",amount:0.09072400584733673},
            {name: "Italy",amount:0.09072400584733673},
            {name: "Hong Kong",amount:0.09072400584733673},
            {name: "Japan",amount:0.09072400584733673},
            {name: "Mexico",amount:0.09072400584733673},
            {name: "South Korea",amount:0.09072400584733673},
            {name: "Malaysia",amount:0.09072400584733673},
            {name: "Thailand",amount:0.09072400584733673},
            {name: "Taiwan",amount:0.09072400584733673},
            {name: "Russia",amount:0.09072400584733673},
            {name: "Singapore",amount:0.09072400584733673},
            {name: "United States of America",amount:0.09072400584733673},
            {name: "Saudi Arabia",amount:0.09072400584733673},
            {name: "South Africa",amount:0.09072400584733673}
        ];
    
        GLB = new ThreeGlobe(); // eslint-disable-line
    
        const countries_admin_features = countries.features.filter(d => countries_we_have.map(c => c.name).includes(d.properties.ADMIN));
        GLB
            .globeImageUrl(earthDark)
            .polygonsData(countries_admin_features)
            .polygonCapColor(() => "transparent")
            .polygonSideColor(() => "rgba(115, 198, 189, 1)")
            .polygonStrokeColor(() => "#264362")
            .showGraticules(true);
        GLB
            .polygonAltitude((feature) => countries_we_have.find(item => item.name == feature.properties.ADMIN).amount / 4);
    
        const probablyMobileDevice = navigator 
                                     && navigator.userAgentData
                                     && (navigator.userAgentData.mobile || navigator.connection.effectiveType != "4g");
        const globeResolution = probablyMobileDevice ? 1 : 3; // higher the number, slowest the processment
        GLB
            .hexPolygonsData(countries.features)
            .hexPolygonResolution(globeResolution)
            .hexPolygonMargin(0.6)
            .hexPolygonColor(() => "rgba(115, 198, 189, 1)")
            .showAtmosphere(true)
            .atmosphereColor("rgba(115, 198, 189, 1)")
            .atmosphereAltitude(0.25);
    
    
        renderer = new THREE.WebGLRenderer({ alpha: true }); // eslint-disable-line
        renderer.setClearColor( 0x000000, 0 );

        if(window.innerWidth > 500) {
          renderer.setSize(window.innerWidth, window.innerHeight);
        } else {
          renderer.setSize(window.innerWidth, (window.innerHeight / 2));
        }

        document.getElementById("globeViz").appendChild(renderer.domElement);
    
        scene = new THREE.Scene(); // eslint-disable-line
        scene.add(GLB);
        scene.add(new THREE.AmbientLight(0xbbbbbb)); // eslint-disable-line
    
        camera = new THREE.PerspectiveCamera(); // eslint-disable-line

        if(window.innerWidth > 500) {
          camera.aspect = window.innerWidth/ window.innerHeight;
        } else {
          camera.aspect = window.innerWidth/ (window.innerHeight / 2);
        }

        camera.updateProjectionMatrix();
        camera.position.z = 290;

        window.camera = camera;

        (function animate() {
          globeAnimationStarted = true;

          GLB.rotation.y += partialY;
          GLB.rotation.x += partialX;

          renderer.render(scene, camera);

          window.rendered = true;
          requestAnimationFrame(animate);
        })();
      });
  });

}
function inputComponent(content,language,filterDatalist,setFilterDatalist,localitiesData,avoid,setTargetCity) {
  return <>
    <input type="search"
      autoComplete="off"
      name="cityFinder_input"
      id="cityFinder_input"
      placeholder={content.Search.placeholder[language]}
      value={filterDatalist}
      onChange={function(e){
        setFilterDatalist(e.target.value.toLowerCase());
      }}
      onFocus={function(){
        stopDemoCarousel();
        eraseTargetCity(cityFinder_input,15);
      }}
    />

    <div className="hasDatalist">
      <ul className="datalist">
        {
          localitiesData ? 
            Object.values(localitiesData["NEW LOCALITIES"])
              .filter(cd => !avoid.includes(cd.locality_id))
              .filter(cd =>
                  filterDatalist == ""
                  || cd["Administrative Data - City County Metro Area"].toLowerCase().includes(filterDatalist)
                  || cd["Administrative Data - State or Province"].toLowerCase().includes(filterDatalist)
                  || cd["Administrative Data - Region"].toLowerCase().includes(filterDatalist)
                  || cd["Administrative Data - Country"].toLowerCase().includes(filterDatalist)
              )
              .sort((a,b) => {
                return filterDatalist ? b["Population Density Patterns - Available Product"] - a["Population Density Patterns - Available Product"] : [
                  a["Administrative Data - City County Metro Area"],
                  a["Administrative Data - State or Province"],
                  a["Administrative Data - Country"],
                  a["Administrative Data - Region"]
                ].filter(i => i && i.length > 0).length - [
                  b["Administrative Data - City County Metro Area"],
                  b["Administrative Data - State or Province"],
                  b["Administrative Data - Country"],
                  b["Administrative Data - Region"]
                ].filter(i => i && i.length > 0).length;
              })
              .map((cd,idx) => {
                const term = [
                  cd["Administrative Data - City County Metro Area"],
                  cd["Administrative Data - State or Province"],
                  cd["Administrative Data - Country"],
                  cd["Administrative Data - Region"]
                ].filter(i => i && i.length > 0).join(", ");

                return <li key={idx} className="item">
                <button onClick={function(){
                  cityFinder(cd.locality_id,setTargetCity,localitiesData);
                  setFilterDatalist(cd["Administrative Data - City County Metro Area"]);
                  cityFinder_input.blur();
                }}>
                  {term}
                </button>
              </li>;
              }) : <></>
        }
      </ul>
    </div>
  </>;
}
function Globe({language,content}) {
  if(!content) 
    return <></>;
  
  const [filterDatalist, setFilterDatalist] = useState(""); // eslint-disable-line
  const [localitiesData, setLocalitiesData] = useState(null); // eslint-disable-line
  const [targetCity, setTargetCity] = useState(null); // eslint-disable-line

  worldwideLocalities.init(setLocalitiesData).then(localitiesData => {
    demonstrateCitiesSearch(localitiesData,setTargetCity);
    initGlobe(localitiesData);
  });

  const avoid = [
    "Country",
    "Russia",
    "Ukraine",
    "Belarus",
    "Poland",
    "Slovakia",
    "Hungary",
    "Romania",
    "Bulgaria",
    "Turkey",
    "Georgia",
    "Azerbaijan",
    "Kazakhstan"    
  ];

  return (<>
    <section id="Search">
      <div className="container">
        <div className="onDesktop">
          <SectionHeader 
            Headline_1={content.Search.Headline_1[language]}
            Headline_2={`${localitiesData && localitiesData["NEW LOCALITIES"] ? Object.keys(localitiesData["NEW LOCALITIES"]).length : ""} ${content.Search.Headline_2[language]}`}
            Button={content.Search.Button[language]}
          />
        </div>

        <article className="citySearch">
          <div className="holder">
            <div className="cityFinder">
              <div className="findComponents">
                <div className="onDesktop">
                  {window.innerWidth > 1000 ? 
                    inputComponent(content,language,filterDatalist,setFilterDatalist,localitiesData,avoid,setTargetCity)
                    : <></>
                  }
                </div>
              </div>

              {targetCity ? targetCityDetails(targetCity,localitiesData["NEW UNITS"].reference,content) : ""}
            </div>
          </div>
        </article>
      </div>

      <div id="globeViz">
        {window.innerWidth <= 1000 ? 
          <div className="onMobile">
            <SectionHeader 
                Headline_1={content.Search.Headline_1[language]}
                Headline_2={content.Search.Headline_2[language]}
                Button={content.Search.Button[language]}
              />
            <article className="container citySearch">
              <div className="holder">
                <div className="cityFinder">
                  <div className="findComponents">
                      {inputComponent(content,language,filterDatalist,setFilterDatalist,localitiesData,avoid,setTargetCity)}
                  </div>
                </div>
              </div>
            </article>
          </div>
          : <></>
        }
      </div>
    </section>

    {/* <section>
      <Marketplace />
    </section> */}
  </>);
}

export default Globe;