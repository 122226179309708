import React from "react";

import "./CityOps.scss";
import ProductDescription from "../ProductDescription/ProductDescription";
import tableTexts from "../../helpers/tableTexts";
import descriptionTexts from "../../helpers/descriptionTexts";
import S3Image from "../../helpers/S3Image";
import playSlider from "../../helpers/playSlider";

function customImagery() {
  const images = [
    S3Image("dist/CITYDATA_CITYWORKS_Image_2.png"),
    S3Image("dist/CITYDATA_CITYWORKS_Image_3.png"),
    S3Image("dist/CITYDATA_CITYWORKS_Image_4.png"),
    S3Image("dist/CITYDATA_CITYWORKS_Image_5.png")
  ];

  playSlider("#cityops",images.length);

  return (
    <div className="devices">
      <div className="phone">
        <div className="phoneMockup">
          <div className="collectionHolder">
              <div className="collection">
                <div className="images">
                  <div className="scroller">
                    {images.map((imgSrc,idx) => 
                      <figure className="hasImage" key={idx}>
                        <img className="cityworksImage" data-src={imgSrc} alt="" />
                      </figure>
                    )}
                  </div>
                </div> {/* .collection */}            
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function getContent(language,content) {
  if(!content) 
    return <></>;

  return (
    <div id="cityops" className="hasSectionContent">
      <ProductDescription 
          Headline_1={content.CityOps.Headline_1[language]}
          Headline_2={content.CityOps.Headline_2[language]}
          Button={content.CityOps.Button[language]}
          ButtonHref={content.CityOps.Button["Relevent_Link"]}
          descriptionTexts={descriptionTexts(content.CityOps,language)}
          imagery={customImagery()}
          tableTexts={tableTexts(content.CityOps,language)}
          SectionHeaderLogoImagePath="https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/cityops_co_blob_2letter.png"
          SectionHeaderLogoImageDescription="CityOPS badge logo"
      />
    </div>
  );
}

function CityOps({language,content}) {
  return getContent(language,content);
}

export default CityOps;