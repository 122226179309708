import React, { useState } from "react";

import { useParams } from "react-router-dom";

import "./fonts/Roboto/Roboto-Regular.ttf";
import "./App.scss";

import Header from "./components/Header/Header";

import content from "./helpers/Content";

import Footer from "./components/Footer/Footer";

import TagManager from "react-gtm-module";
import InternalContent from "./components/InternalContent/InternalContent";
import getLanguage from "./helpers/getLanguage";
import summaryContent from "./components/SummaryContent/SummaryContent";
import LoaderAnimation from "./components/LoaderAnimation/LoaderAnimation";

TagManager.initialize({
  gtmId: "GTM-TWZTBF5"
});

function Privacy() {
  const [language,setLanguage] = useState(getLanguage);
  const [contentData, setContentData] = useState();

  const updateLanguageDefined = newLanguage => {
    setLanguage(newLanguage);
  };  
  
  content.init(setContentData);

  const params = useParams();
  const hasParams = Object.keys(params).length > 0;

  let contentToDeliver = (contentData,language,params) => hasParams ? 
    InternalContent(contentData,language,"privacy",params) :
    summaryContent(contentData,language,"privacy");

  return (
      <div className='App'>
          <LoaderAnimation 
              content={contentData}
          />

          <Header
              content={contentData}
              language={language}
              setLanguage={updateLanguageDefined}
          />

          {contentToDeliver(contentData,language,params)}

          <Footer
              content={contentData}
              language={language}
          />
      </div>
  );
}

export default Privacy;
