import React, { useState } from "react";

import "./Citysim.scss";
import ProductDescription from "../ProductDescription/ProductDescription";
import tableTexts from "../../helpers/tableTexts";
import descriptionTexts from "../../helpers/descriptionTexts";
import S3Image from "../../helpers/S3Image";
import playSlider from "../../helpers/playSlider";

function customImagery() {
  const images = [
    S3Image("CITYSIM/scaled/dist/citydata_citysim_1.png?cache=12"),
    S3Image("CITYSIM/scaled/dist/citydata_citysim_2.png?cache=12"),
    S3Image("CITYSIM/scaled/dist/citydata_citysim_3.png?cache=12"),
    S3Image("CITYSIM/scaled/dist/citydata_citysim_4.png?cache=12"),
    S3Image("CITYSIM/scaled/dist/citydata_citysim_5.png?cache=12"),
    S3Image("CITYSIM/scaled/dist/citydata_citysim_6.png?cache=12")
  ];

  playSlider("#citysim",images.length);
  return (
    <div className="laptopMockup devices">
      <div className="frame"></div>

      <div className="collectionHolder">
          <div className="collection">
            <div className="images">
              <div className="scroller">
                {images.map((imgSrc,idx) => 
                  <figure className="hasImage" key={idx}>
                    <img className="citydashImage" data-src={imgSrc} alt="" />
                  </figure>
                )}
              </div>
            </div> {/* .collection */}            
          </div>
      </div>
    </div>    
  );
}
function getContent(language,content, slidePosition) {
  if(!content) 
    return <></>;

  return (
    <div id="citysim" className="hasSectionContent">
      <ProductDescription 
          Headline_1={content.CitySim.Headline_1[language]}
          Headline_2={content.CitySim.Headline_2[language]}
          Button={content.CitySim.Button[language]}
          ButtonHref={content.CitySim.Button["Relevent_Link"]}
          descriptionTexts={descriptionTexts(content.CitySim,language)}
          tableTexts={tableTexts(content.CitySim,language)}
          imagery={customImagery(slidePosition)}
          SectionHeaderLogoImagePath="https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/citysim_cs_blob_2letter.png"
          SectionHeaderLogoImageDescription="CitySim badge logo"
      />
    </div>
  );
}

function Citysim({language,content}) {
  const [slidePosition] = useState(0);

  return getContent(language,content, slidePosition);
}

export default Citysim;