import React from "react";

// import icon_twitter from '../../images/';
// import icon_link from '../../images/';
// import icon_linkedin from '../../images/';
// import icon_email from '../../images/';

import "./Footer.scss";
import S3Image from "../../helpers/S3Image";

function Footer({content,language}) {
  if(!content) {
    return <></>;
  } else {
    return (
      <footer id="Footer" className="hasSectionContent">
        <div className="SectionHeader"></div>
  
        <div className="container">
          <ul id="medias">          
            <li className="media twitter">
              <a href="https://twitter.com/CityDataAI"
                target="_blank"
                rel="noopener noreferrer">
                <i style={{
                  backgroundImage: `url(${S3Image("icons/twitter.png")})`
                }}>twitter</i>
              </a>
            </li>
            <li className="media linkedin">
              <a href="https://www.linkedin.com/company/citydata-ai/"
                target="_blank"
                rel="noopener noreferrer">
                <i style={{
                  backgroundImage: `url(${S3Image("icons/linkedin.png")})`
                }}>linkedin</i>
              </a>
            </li>
            <li className="media email">
              <a href="mailto:business@citydata.ai"
                target="_blank"
                rel="noopener noreferrer">
                <i style={{
                  backgroundImage: `url(${S3Image("icons/mail.png")})`
                }}>email</i>
              </a>
            </li>
          </ul>
  
          <p id="getSmarter">
            {content.Footer.Description_text_1[language]}
            <a href={content.Footer.Button["Relevent_Link"]} target="_blank" title="Sign-up" className="cta" rel="noreferrer">{content.Footer.Button[language]}</a>
          </p>
  
          <div id="copyright">
            <p>{content.Footer.Description_text_2[language]} {(new Date()).getFullYear()} </p><address><strong>{content.Footer.Description_text_3[language]}</strong>, {content.Footer.Description_text_4[language]}</address>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;