import React from "react";

import auth from "../../helpers/auth";

import "./SignIn.scss";

function SignIn({cta,href}) {
  if(auth.isLogged())
    return <></>;


  const [redirect, setRedirect] = React.useState("");   
  setTimeout(() => {
    let url = document.querySelector("#Search #citiesDetails td a")?.href.split("?")[1];

    if(url) {
      setRedirect(`?${url}`);
    }
  },100);

  return (
    <a href={href || `https://auth.citydata.ai/${redirect}`}
       className="cta SignIn"
       target="_blank" rel="noreferrer">
      {cta}
    </a>
  );
}

export default SignIn;